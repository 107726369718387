import React from 'react'
import { IndexRedirect, Route, Router, browserHistory } from 'react-router'
import initializeStore from './store'
import { Provider } from 'react-redux'
import { syncHistoryWithStore } from 'react-router-redux'
import Layout from './Layout'
import { routes as ActivityRoutes } from './activities'
import { routes as OrderRoutes } from './orders'
import { routes as MemberRoutes } from './member'
import { routes as MembershipsRoutes } from './memberships'
import { routes as StyleguideRoutes } from './styleguide'
import { IS_PRODUCTION } from 'config'
import { NotFound } from 'components'
import * as MemberViews from '../main/member/views'

const store = initializeStore()

// Make the routing information available in the store
const history = syncHistoryWithStore(browserHistory, store)

const Routes = (
  // React Redux `Provider` component to enable the `connect` function to connect to the Redux store.
  <Provider store={ store }>
    <Router history={ history } >
      <Route path="/" component={ Layout }>
        <IndexRedirect to="/activities" />
        { OrderRoutes }
        { ActivityRoutes }
        { MemberRoutes }
        { MembershipsRoutes }
        {
          (IS_PRODUCTION || process.env.DISPLAY_STYLEGUIDE) &&
          StyleguideRoutes
        }
        <Route path="/reset-password" component={ MemberViews.ResetPassword } />
      </Route>
      <Route path="*" component={ NotFound } />
    </Router>
  </Provider>
)

export default Routes
