import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { MemberLookupForm } from '../forms'
import * as apiActions from 'api-actions'
import * as flashActions from 'redux-flash'

const propTypes = {
  memberLookupFail: PropTypes.func,
  memberLookupSuccess: PropTypes.func,
  requestMemberDetails: PropTypes.func.isRequired,
}

const defaultProps = {}

function MemberLookup ({
  memberLookupFail,
  memberLookupSuccess,
  requestMemberDetails
}) {
  return (
    <div className="row content">
      <div className="login-container login-container-small">
        <div className="new-member-container">
          <h2>Forgot Password</h2>
          <p>Please enter your email and instructions will be sent for password reset.</p>
          <MemberLookupForm
            onSubmit={ requestMemberDetails }
            onSubmitSuccess={ memberLookupSuccess }
            onSubmitFail={ memberLookupFail }
          />
        </div>
      </div>
    </div>
  )
}

MemberLookup.propTypes = propTypes

MemberLookup.defaultProps = defaultProps

function mapStateToProps () {
  return {}
}

const mapDispatchToProps = {
  requestMemberDetails: apiActions.requestMemberDetails,
  memberLookupSuccess: () => flashActions.flashMessage('We have sent a password reset link to the email provided.'),
  memberLookupFail: (errors) => flashActions.flashErrorMessage(`Sorry there was an error: ${errors.message}`),
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(MemberLookup)
