import React from 'react'
import PropTypes from 'prop-types'
import { displayCurrency } from 'utils'

const propTypes = {
  price: PropTypes.number,
  total: PropTypes.number,
  displayTitle: PropTypes.string.isRequired,
  removeItem: PropTypes.func
}

const defaultProps = {
  removeItem: null,
}

function PriceDetail ({ price, total, displayTitle, removeItem }) {
  const isFreePrice = !price || price <= 0
  const isFreeTotal = !total || total <= 0
  return (
    <div>
      <div className="ticket-type">
        <p>{ displayTitle } x { isFreePrice ? 'Free' : displayCurrency(price) }</p>
        <p className="price">
         { isFreeTotal ? 'Free' : displayCurrency(total) }
        </p>
        {removeItem && (
          <button 
            type="button" 
            aria-label={`Remove ${displayTitle} ${isFreeTotal ? '(Free)' : displayCurrency(total)} from order`}
            className="button-remove"
            onClick={() => removeItem()}
            >
              X
          </button>
        )}
      </div>
      <hr/>
    </div>
  )
}

PriceDetail.propTypes = propTypes
PriceDetail.defaultProps = defaultProps

export default PriceDetail
