function venueClass(title) {
  const venues = ['olympic park', 'olympic oval', 'soldier hollow']
  let className = null
  venues.forEach((venue) => {
    if (title.toLowerCase().indexOf(venue) === -1) return
    className = venue.split(' ').join('-')
  })
  return className
}

export default venueClass