import React from 'react'
import { compose } from 'redux'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import { lpForm } from 'lp-form'
import { Input, SubmitButton } from 'lp-components'

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
}

const defaultProps = {}

function CouponForm ({
  handleSubmit,
  pristine,
  submitting,
  invalid
}) {
  return (
    <div>
      <form onSubmit={ handleSubmit }>
        <Field
          name="coupon"
          className="promo-input"
          component={ Input }
          label="Promo Code (optional)"
        />
        <SubmitButton
          className="button-primary button-promo"
          invalid={ invalid }
          pristine={ pristine }
          submitting={ submitting }
        >
          Add
        </SubmitButton>
      </form>
    </div>
  )
}

CouponForm.propTypes = propTypes
CouponForm.defaultProps = defaultProps

export default compose(
  lpForm({ name: 'membership-coupon-form' }),
)(CouponForm)
