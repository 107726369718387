import React from "react";
import PropTypes from "prop-types";
import * as Types from "types";
import { connect } from "react-redux";
import { compose, withState } from "recompose";
import { selectors } from "../reducer";
import { selectors as memberSelectors } from "../../member/reducer";
import { Content, LoadingIndicator, FlashError } from "components";
import BillingDetails from "../../activities/components/billing-details";
import { AddOnDonationForm } from "../../orders/forms";
import { modifyProps, onMount } from "lp-utils";
import * as effects from "effects";
import BillingForm from "../forms/billing-form";
import * as apiActions from "api-actions";
import { IntlProvider } from "react-intl";
import { centamanFormError, scrollToTop } from "utils";
import * as LS from "local-storage";
import { connectParams, waitFor } from "lp-hoc";
import * as routerActions from "react-router-redux";
import { CouponForm } from "../forms";

const propTypes = {
  billingError: PropTypes.object,
  checkCouponValidity: PropTypes.func.isRequired,
  checkout: Types.membershipCheckout.isRequired,
  handleCouponError: PropTypes.func.isRequired,
  mappedMembership: Types.mappedMembership.isRequired,
  primaryMember: Types.member.isRequired,
  setBillingError: PropTypes.func.isRequired,
  skipPaymentDetails: PropTypes.bool,
  ticketType: PropTypes.string.isRequired,
  updateMembershipCheckout: PropTypes.func.isRequired,
  verifyRecaptcha: PropTypes.func.isRequired,
};

const defaultProps = {
  billingError: null,
};

function Billing({
  billingError,
  checkCouponValidity,
  checkout: { items, totals },
  handleCouponError,
  mappedMembership,
  primaryMember,
  setBillingError,
  skipPaymentDetails,
  ticketType,
  updateMembershipCheckout,
  verifyRecaptcha,
}) {
  return (
    <IntlProvider locale="en">
      <Content>
        {billingError && (
          <FlashError error={billingError} clearError={setBillingError} />
        )}
        {totals && (
          <div className="ticket-details">
            <BillingDetails
              activity={mappedMembership}
              coupon={totals.coupon}
              couponDescription={totals.couponDescription}
              orderInfo={totals}
              tickets={items}
              isTimedActivity={false}
              removeDonation={() => {
                return updateMembershipCheckout({ donationAmount: 0 });
              }}
            />
            <CouponForm
              onSubmit={checkCouponValidity}
              onSubmitSuccess={(response, _, { reset }) => {
                // Raise an error and clear the cart if the coupon could not be found/applied
                if (response && response.errorDetails) {
                  handleCouponError(response.errorDetails);
                  return updateMembershipCheckout({ coupon: null });
                }
                if (
                  response &&
                  response.success &&
                  response.couponDetails.isValid
                ) {
                  return updateMembershipCheckout({
                    coupon: response.couponDetails.coupon,
                  })
                    .then(reset)
                    .catch(handleCouponError);
                }
              }}
              onSubmitFail={(errors) => {
                // Only show the coupon error if the api response has a title and message
                const couponError =
                  errors && errors.title && errors.message ? errors : null;
                return handleCouponError(couponError);
              }}
            />
            <AddOnDonationForm
              onSubmit={({ donationAmount }) => {
                updateMembershipCheckout({ donationAmount });
              }}
            />
          </div>
        )}
        <BillingForm
          onSubmit={(params) => {
            return verifyRecaptcha(window.grecaptcha.getResponse()).then(() => {
              return effects.createMembershipOrder({
                params,
                skipPaymentDetails: totals.total <= 0,
              });
            });
          }}
          orderInfo={totals}
          setBillingError={setBillingError}
          tickets={items}
          ticketType={ticketType}
          initialValues={{
            email: primaryMember.email,
            phone: primaryMember.phone,
          }}
          skipPaymentDetails={skipPaymentDetails}
          validateRecaptcha={verifyRecaptcha}
          hidePhoneNumber
        />
      </Content>
    </IntlProvider>
  );
}

function mapStateToProps(state) {
  return {
    checkout: selectors.checkout(state),
    membership: selectors.selectedMembership(state),
    primaryMember: memberSelectors.primaryMember(state),
  };
}

const mapDispatchToProps = {
  checkMembershipCoupon: apiActions.checkMembershipCoupon,
  fetchMembershipDetails: apiActions.fetchMembershipDetails,
  redirect: routerActions.replace,
  updateMembershipCheckout: apiActions.updateMembershipCheckout,
  verifyRecaptcha: apiActions.verifyRecaptcha,
};

function fetchBillingData({
  id,
  fetchMembershipDetails,
  membership,
  redirect,
  updateMembershipCheckout,
}) {
  scrollToTop();
  if (membership && membership.id === id) return;

  return fetchMembershipDetails(id)
    .then(() => {
      updateMembershipCheckout({
        membershipId: id,
        ...LS.getCheckout(),
      });
    })
    .catch((e) => {
      if (e && e.status === 404) redirect("/memberships/not-found");
    });
}

function modify({
  checkMembershipCoupon,
  checkout: { totals },
  membership,
  setBillingError,
}) {
  if (!membership) return {};
  return {
    mappedMembership: {
      ...membership,
      thumbnail: membership.membershipImage,
    },
    ticketType: "Membership",
    checkCouponValidity: ({ coupon }) => {
      return checkMembershipCoupon(coupon, membership.id);
    },
    handleCouponError: (errors) => {
      scrollToTop();
      if (!errors) return centamanFormError();
      return setBillingError(errors);
    },
    skipPaymentDetails: totals.total <= 0,
  };
}

Billing.propTypes = propTypes;
Billing.defaultProps = defaultProps;

export default compose(
  connectParams("id"),
  connect(mapStateToProps, mapDispatchToProps),
  withState("billingError", "setBillingError", null),
  modifyProps(modify),
  onMount(fetchBillingData),
  waitFor(["membership", "checkout"], LoadingIndicator)
)(Billing);
