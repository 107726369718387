import React from 'react'
import PropTypes from 'prop-types'
import { DateInput } from 'lp-components'
import moment from 'moment'

const propTypes = {
  input: PropTypes.object,
}

function MomentDateInput ({ input, nullByDefault, ...rest }) {
  const defaultDate = nullByDefault ? null : moment()
  const newValue = input.value._isValid ? input.value.toDate() : defaultDate
  
  const newOnChange = (val) => {
    const momentizedValue = moment(val)
    const newValue = momentizedValue._isValid ? momentizedValue : null 
    input.onChange(newValue)
  }
  const newInput = { ...input, value: newValue, onChange: newOnChange, onBlur: () => {} }
  
  return (
    <DateInput input={ newInput } placeholderText="MM/DD/YYYY" { ...rest } />
  )
}

MomentDateInput.propTypes = propTypes

export default MomentDateInput
