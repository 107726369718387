import React from 'react'
import PropTypes from 'prop-types'
// import { compose } from 'redux'
// import { onMount } from 'lp-utils'
import ResponsiveNavItem from './responsive-nav-item'
// import { triggerResponsiveNav } from 'utils'

const propTypes = {
  item: PropTypes.object.isRequired,
}

function ResponsiveNavDropdown ({ item: { title, children } }) {
  return (
    <li className="">
      <a className="dropdown-trigger" href="#">{ title }</a>
      <ul className="dl-submenu">
        <li className="dl-back"><a href="#">Back</a></li>
        { children.map((childItem, i) => {
            return <ResponsiveNavItem item={ childItem } key={ i } />
          })
        }
      </ul>
    </li>
  )
}

ResponsiveNavDropdown.propTypes = propTypes

export default ResponsiveNavDropdown