import React from "react";
import * as Types from "types";
import { ExternalLink } from 'components'

const propTypes = {
  venue: Types.venue.isRequired,
}

const defaultProps = {}

function ParkMap({ venue }) {
  const {
    address,
    streetAddress,
    city,
    state,
    googleMapLink,
    mobileLocationMap,
    locationMap,
  } = venue;

  return (
    <div className="park-locations">
      <p className="park-header">{mapLabel(venue)}</p>
      <hr />
        {address && (
          <p className="address-copy">
            {streetAddress} <br />
            {`${city}, ${state}`}
          </p>
        )}
        {mobileLocationMap && (
           <img
           className="mobile-map"
           src={mobileLocationMap}
           alt="Park Locations"
         />
        )}
        {locationMap && (
          <img className="desktop-map" src={locationMap} alt={`${venue.name} location`} />
        )}
        <ExternalLink to={googleMapLink} className="button-chill">
          Maps &amp; Directions
        </ExternalLink>
    </div>
  );
}

function mapLabel(venue) {
  return venue.slug === Types.ALL_ACTIVITIES_VENUE_SLUG ? "Venue Locations" : venue.name
}

ParkMap.propTypes = propTypes
ParkMap.defaultProps = defaultProps

export default ParkMap;
