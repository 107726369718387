import React from 'react'
import { compose } from 'redux'
import {
  Field,
  FormSection,
  propTypes as formPropTypes,
} from 'redux-form'
import { ButtonArea, Input, SubmitButton } from 'lp-components'
import { lpForm } from 'lp-form'

function RegisterMemberForm ({ handleSubmit, invalid, pristine, submitting }) {
  return (
    <form onSubmit={ handleSubmit }>
      <FormSection name="newMember">
        <Field name="firstName" component={ Input } type="text" />
        <Field name="lastName" component={ Input } type="text" />
        <Field name="email" component={ Input } type="text" autoComplete="username" />
        <Field name="password" label={ 'Create Password' } component={ Input } type="password" autoComplete="new-password" />
      </FormSection>
      <Field name="passwordConfirmation" label={ 'Confirm Password' } component={ Input } type="password" autoComplete="new-password" />
      <ButtonArea>
        <SubmitButton className="button-chill" { ...{ invalid, pristine, submitting } }>
          Register
        </SubmitButton>
      </ButtonArea>
    </form>
  )
}

RegisterMemberForm.propTypes = formPropTypes

export default compose(
  lpForm({
    name: 'register-member',
    constraints: {
      'newMember.firstName': { presence: true, length: { maximum: 30 } },
      'newMember.lastName': { presence: true, length: { maximum: 30 } },
      'newMember.email': { presence: true, length: { maximum: 60 } },
      'newMember.password': { presence: true, length: { maximum: 50 } },
      'passwordConfirmation': { presence: true, equality: 'newMember.password' },
    }
  })
)(RegisterMemberForm)
