import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { ResetPasswordForm } from '../forms'
import * as flashActions from 'redux-flash'
import * as apiActions from 'api-actions'
import * as routerActions from 'react-router-redux'
import { connectQuery } from 'lp-hoc'

const propTypes = {
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  token: PropTypes.string,
}

const defaultProps = {
  token: '',
}

function ResetPassword ({
  flashErrorMessage,
  flashSuccessMessage,
  push,
  resetPassword,
  token,
}) {
  return (
    <main>
      <div className="row content">
        <div className="login-container login-container-small">
          <h2>Reset Password</h2>
          <p>Please select a new password.</p>
          <ResetPasswordForm
            initialValues={{ member: { token } }}
            onSubmit={ resetPassword }
            onSubmitSuccess={ () => {
              flashSuccessMessage('You successfully reset your password.')
              push(`/member/login`)
            } }
            onSubmitFail={ () => flashErrorMessage('We are unable to reset your password at this time. Please contact customer service.') }
          />
          <Link className="login-link" to="/member/login">Back to Login</Link>
        </div>
      </div>
    </main>
  )
}

ResetPassword.propTypes = propTypes
ResetPassword.defaultProps = defaultProps

function mapStateToProps () {
  return {
  }
}

const mapDispatchToProps = {
  flashSuccessMessage: flashActions.flashMessage,
  flashErrorMessage: flashActions.flashErrorMessage,
  push: routerActions.push,
  resetPassword: apiActions.resetPassword,
}

export default compose(
  connectQuery('token'),
  connect(mapStateToProps, mapDispatchToProps),
)(ResetPassword)
