import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  includedQuantity: PropTypes.number,
  value: PropTypes.number,
  additionalQuantityPrice: PropTypes.number,
  updateQuantity: PropTypes.func,
}

function MembershipQuantitySelect ({
  includedQuantity,
  additionalQuantityPrice,
  value,
  updateQuantity,
}) {
  return (
    <div>
      <div className="ticket-amount">
        <h5 className="quantity">{ value }</h5>
        <span>
          <h5>Family Members</h5>
          { includedQuantity && <p> { includedQuantity } included</p> }
          { additionalQuantityPrice && <p>Additional = ${ additionalQuantityPrice }/person</p> }
        </span>
        <div className="button-group">
          <button type="button"
                  className="button-ticket-amount"
                  style={{marginBottom: 3 + 'px'}}
                  onClick={ () => updateQuantity(value, 1) }>
          +</button>
          <button type="button"
                  className="button-ticket-amount"
                  disabled={ value === 0 }
                  onClick={ () => updateQuantity(value, -1) }>
          -</button>
        </div>
      </div>
    </div>
  )
}

MembershipQuantitySelect.propTypes = propTypes

export default MembershipQuantitySelect
