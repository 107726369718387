import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  optionTitle: PropTypes.string.isRequired,
  optionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

function SelectOption ({ optionTitle, optionValue }) {
  return (
    <option value={ optionValue }>{ optionTitle }</option>
  )
}

SelectOption.propTypes = propTypes

export default SelectOption