import { Component } from 'react'

// Turn a pure functional component into a stateful one.
export default function componentize () {
  return funcComponent => 
    class Componentized extends Component {
      render () {
        return funcComponent(this.props)
      }
    }
}