import { uniq, map, differenceWith } from 'lodash'
import { eachDay, isSameDay, format } from 'date-fns'
import stripTimezone from './strip-timezone'

// Given an array of timeslots and a time range, returns an array of excluded dates.

function getExcludedDatesFromTimeSlots(
  timeSlots,
  startDate,
  endDate,
  includeSoldOut = false
) {
  const allDates = eachDay(startDate, endDate);
  const availableDates = getAvailableDatesFromTimeSlots(
    timeSlots,
    includeSoldOut
  );
  return differenceWith(allDates, availableDates, isSameDay)
}

/* HELPERS */

function getAvailableDatesFromTimeSlots(timeSlots, includeSoldOut) {
  const availableTimeSlots = includeSoldOut
    ? timeSlots
    : timeSlots.filter((timeSlot) => !timeSlot.soldOut)
  const availableDates = uniq(map(availableTimeSlots, 'startDate'))
  return availableDates.map(formatDate)
}

function formatDate(date) {
  return format(stripTimezone(date), 'YYYY-MM-DD')
}

export default getExcludedDatesFromTimeSlots
