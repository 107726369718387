import { combineReducers } from 'redux'
import {
  reducer as activityReducer,
  reducerKey as activityReducerKey,
} from './activities'
import {
  reducer as orderReducer,
  reducerKey as orderReducerKey,
} from './orders'
import {
  reducer as headerReducer,
  reducerKey as headerReducerKey,
} from './header'
import {
  reducer as styleguideReducer,
  reducerKey as styleguideReducerKey,
} from './styleguide'
import {
  reducer as membershipsReducer,
  reducerKey as membershipsReducerKey,
} from './memberships'
import {
  reducer as memberReducer,
  reducerKey as memberReducerKey,
} from './member'

const reducerKey = 'root'

const reducer = combineReducers({
  [activityReducerKey]: activityReducer,
  [orderReducerKey]: orderReducer,
  [headerReducerKey]: headerReducer,
  [styleguideReducerKey]: styleguideReducer,
  [membershipsReducerKey]: membershipsReducer,
  [memberReducerKey]: memberReducer,
})

export { reducer, reducerKey }
