import React from 'react'
import { compose } from 'redux'
import { Link } from 'react-router'
import {
  Field,
  FormSection,
  propTypes as formPropTypes,
} from 'redux-form'
import { ButtonArea, Input, SubmitButton } from 'lp-components'
import { lpForm } from 'lp-form'

function MemberLoginForm ({ handleSubmit, invalid, pristine, submitting }) {
  return (
    <form onSubmit={ handleSubmit }>
      <FormSection name="member">
        <Field name="email" component={ Input } type="text" autoComplete="username" />
        <Field name="password" label={ 'Password' } component={ Input } type="password" autoComplete="current-password" />
      </FormSection>
      <ButtonArea>
        <SubmitButton className="button-chill" { ...{ invalid, pristine, submitting } }>
          Login to Checkout
        </SubmitButton>
        <Link className="login-link" to="/member/lookup"> Forgot Password? </Link>
      </ButtonArea>
    </form>
  )
}

MemberLoginForm.propTypes = formPropTypes

export default compose(
  lpForm({
    name: 'member-login',
    constraints: {
      'member.email': { presence: true },
      'member.password': { presence: true },
    }
  })
)(MemberLoginForm)
