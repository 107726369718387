import React from 'react'
import PropTypes from 'prop-types'
import dismissImg from 'images/dismiss.png'

const propTypes = {
  error: PropTypes.object.isRequired,
  clearError: PropTypes.func.isRequired,
}

function FlashError ({ error, clearError }) {
  return (
    <div className="flash-message failure">
      <div className="dismiss-flash" onClick={ () => clearError(null) }>
        <img src={ dismissImg } alt="dismiss"/>
      </div>
      <h4 className="error-title">{ error.title }</h4>
      { error.spreedly
        ? <div>{ renderSpreedlyErrors(error) }</div>
        : <div><p>{ error.message }</p></div>
      }
    </div>
  )
}

function renderSpreedlyErrors (error) {
  return Object.values(error.errors).map((key,i) => <p key={ i } >{ key }</p>)
}

FlashError.propTypes = propTypes

export default FlashError
