import { applyMiddleware, compose, createStore, combineReducers } from 'redux'
import { routerReducer, routerMiddleware } from 'react-router-redux'
import { browserHistory } from 'react-router'
import thunkMiddleware from 'redux-thunk'
import { reducer as formReducer } from 'redux-form'
import { reducer as modalReducer } from 'redux-modal'
import { middleware as apiMiddleware } from 'api'
import { reducer as apiReducer } from 'lp-redux-api'
import { reducer as flashReducer, middleware as flashMiddleware } from 'redux-flash'
import { reducer as rootReducer, reducerKey as rootKey } from './reducer'
import { IS_PRODUCTION } from 'config'
import { throttle } from 'utils'
import * as LS from 'local-storage'
import { selectors as orderSelectors } from './orders/reducer'

function initializeStore () {
  // Combine the reducers into one that Redux can handle. 
  // The keys below are important, as data in the store will be namespaced by them
  // and each reducer only receives the slice of state according to the key.
  const reducer = combineReducers({
    form: formReducer,
    modal: modalReducer,
    [rootKey]: rootReducer,
    routing: routerReducer,
    api: apiReducer,
    flash: flashReducer,
  })
  // Add support for the Redux Dev Tools in chrome.
  const devToolCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const composeEnhancers = IS_PRODUCTION ? compose : devToolCompose
  // Combine the middlewares into one that Redux can handle
  const enhancers = composeEnhancers(
    applyMiddleware(
      apiMiddleware,
      thunkMiddleware,
      flashMiddleware(),
      routerMiddleware(browserHistory)
    )
  )
  const initialState = {}
  const store = createStore(reducer, initialState, enhancers)

  // Save cart data in session storage
  store.subscribe(throttle(() => {
    const state = store.getState()

    const cart = orderSelectors.cart(state)
    LS.setCart(cart)

    const orderInfo = orderSelectors.orderInfo(state)
    LS.setOrderInfo(orderInfo)
  }, 500))

  // Add store as global window variable for console debugging
  window.store = store
  return store
}

export default initializeStore
