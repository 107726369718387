import React from 'react'
import PropTypes from 'prop-types'
import { modal } from 'utils'

const propTypes = {
  handleHide: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
}

function ErrorModal ({ handleHide, title, message }) {
  return (
    <div>
      <div onClick={ handleHide } className="modal-close">×</div>
      <h4>{ title }</h4>
      <div className="modal-content">
        <p>{ message }</p>
      </div>
    </div>
  )
}

ErrorModal.displayName = 'ErrorModal'

ErrorModal.propTypes = propTypes

export default modal({ destroyOnHide: true })(ErrorModal)
