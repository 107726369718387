/**
 * @name mapFormValuesToArray
 * @param {Array|Object} formValues - An array or object representing selected form values
 * @returns {Array} - Returns an array of selected form values. If an array is provided, then it will be returned without modification.
 */
import { flatten } from './index'

function mapFormValuesToArray (formValues) {
  if (!formValues) return []
  if (Array.isArray(formValues)) return formValues

  return flatten(Object.values(formValues))
}

export default mapFormValuesToArray
