import React from 'react'
import { Route, IndexRoute } from 'react-router'
import * as Views from './views'
import * as OrderViews from '../orders/views'
import Layout from './Layout'

const Routes = (
  <Route path="activities" component={ Layout }>
    <IndexRoute component={ Views.Activities }/>
    <Route path="cart" component={OrderViews.Cart}/>
    <Route path="payment" component={OrderViews.Payment}/>
    <Route path=":id" component={ Views.Activity } />
  </Route>
)

export default Routes
