import React from 'react'
import exact from 'prop-types-exact'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { ExternalLink } from 'components'
import * as Types from 'types'

const propTypes = {
    activity: Types.activity.isRequired,
    locationLabel: PropTypes.string.isRequired,
    locationMarker: PropTypes.string.isRequired
}

const defaultProps = {}

function ActivityPreview ({ activity, locationLabel, locationMarker }) {
    return (
        <div>
            <div className="ticket-card">
                <div>
                    <img src={activity.image} alt=""/>
                    <p className="location-label">
                        <img src={locationMarker} alt=""/>
                        {locationLabel}
                    </p>
                </div>
                <div className="info">
                    <h5>{activity.displayName}</h5>
                    <div className="module line-clamp"></div>
                    <Link to={`/activities/${activity.id}`} className="button-chill" aria-label={`Buy ${activity.displayName}`}>
                        Buy Now
                    </Link>
                    {activity.learnMoreUrl && (
                        <ExternalLink to={activity.learnMoreUrl}>Learn More</ExternalLink>
                    )}
                </div>
                {
                    activity.learnMoreUrl && <ExternalLink text="Learn More" to={activity.learnMoreUrl} aria-label={`Learn more about ${activity.displayName}`}/>
                }
            </div>
        </div>
    )
}

ActivityPreview.propTypes = exact(propTypes)
ActivityPreview.defaultProps = defaultProps

export default React.memo(ActivityPreview)