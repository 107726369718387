
import * as Types from "types"
import { camelCase, isEmpty } from "lodash"
import moment from 'moment'

const formatDateUdf = (dateValue) => {
    if (moment.isMoment(dateValue)) return dateValue.format('YYYY-MM-DD')
    return dateValue
}

function transformFieldValue (value, udf) {
    switch (udf.fieldType) {
        case Types.udfFieldTypes.DATE:
            return formatDateUdf(value)
        default:
            return value
    }
}

function transformFieldType(udf) {
    return camelCase(udf.fieldType)
}

export const serializeUdfs = (formValues, udfs) => {
    const serializedUdfs = []
    Object.entries(formValues).forEach(([fieldName, value]) => {
      const udf = udfs.find(u => u.fieldName === fieldName)
      serializedUdfs.push({
        fieldName,
        value: transformFieldValue(value, udf),
        fieldType: transformFieldType(udf),
      })
    })
    return serializedUdfs
  }

export const convertUdfsArrayToObj = (udfsArray) => {
    if (isEmpty(udfsArray)) return {}
    const obj = {}
    udfsArray.forEach(udf => {
        obj[udf.fieldName] = udf.value
    })
    return obj
}
