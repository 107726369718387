import React from 'react'
import * as Types from 'types'
import exact from 'prop-types-exact'
import PropTypes from 'prop-types'
import ActivityPreview from './ActivityPreview'

const propTypes = {
    venue: Types.venue.isRequired,
    getLocationLabel: PropTypes.func.isRequired,
    getLocationMarker: PropTypes.func.isRequired
}

const defaultProps = {}

function VenueActivityList ({ venue, getLocationLabel, getLocationMarker }) {

    return (
        <div>
            <p className="park-header">{venue.name}</p><hr></hr>
            
            {venue.activities.length > 0 ? (
                <ul>
                {
                    venue.activities.map(activity => {
                        return (
                            <li key={activity.displayName}>
                                <ActivityPreview 
                                    activity={activity} 
                                    locationLabel={getLocationLabel(activity.id)}
                                    locationMarker={getLocationMarker(activity, venue)}
                                />
                            </li>
                        )
                    })
                }
                </ul>
            ) : (
                <p>There are no activities currently available for this venue.</p>
            )}
            
        </div>
    )
}

VenueActivityList.propTypes = exact(propTypes)
VenueActivityList.defaultProps = defaultProps

export default React.memo(VenueActivityList)