import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { MemberLoginForm, RegisterMemberForm, } from '../forms'
import * as apiActions from 'api-actions'
import PropTypes from 'prop-types'
import * as flashActions from 'redux-flash'
import * as routerActions from 'react-router-redux'
import { modifyProps, onMount, connectQuery, } from 'lp-hoc'
import * as LS from 'local-storage'
import { get, scrollToTop } from 'utils'
import * as actions from '../actions'

const propTypes = {
  flashErrorMessage: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  redirectUrl: PropTypes.string.isRequired,
}

const defaultProps = {}

function MemberLogin ({
  flashErrorMessage,
  login,
  push,
  register,
  redirectUrl,
}) {
  return (
    <div className="row content">
      <div className="login-container">
        <div className="col-6 existing-member-container">
          <h2>Log In To Checkout</h2>
          <p>Have a membership? Log in for faster checkout.</p>
          <MemberLoginForm
            onSubmit={ login }
            onSubmitSuccess={ () => push(redirectUrl) }
            onSubmitFail={ () => flashErrorMessage('Something went wrong. Please retry your login details.') }
          />
        </div>
        <div className="col-6 new-member-container">
          <h2>Register</h2>
          <p>Don't have an account? Proceed to checkout as a Guest and you will create an account upon purchase.</p>
          <RegisterMemberForm
            onSubmit={ register }
            onSubmitSuccess={ () => push(redirectUrl) }
            onSubmitFail={ ({ _error }) => {
              scrollToTop()
              flashErrorMessage(`${_error || 'Something went wrong. Please retry your registration details.'}`)
            } }
          />
        </div>
      </div>
    </div>
  )
}

MemberLogin.propTypes = propTypes
MemberLogin.defaultProps = defaultProps

function mapStateToProps () {
  return {}
}

const mapDispatchToProps = {
  clearMemberSession: actions.clearMemberSession,
  flashMessage: flashActions.flashMessage,
  flashErrorMessage: flashActions.flashErrorMessage,
  login: apiActions.requestMemberLogin,
  register: apiActions.registerMember,
  push: routerActions.push,
}

// Determine where the application should redirect to based on the following priorities:
// query param > redirectUrl > localStorage > default
function getRedirectUrl (membershipId, savedRedirectUrl) {
  if (membershipId) return '/memberships/' + membershipId
  if (savedRedirectUrl) return savedRedirectUrl

  const savedMembershipId = LS.getMembershipId()
  return savedMembershipId ? '/memberships/' + savedMembershipId : '/activities'
}

function modify ({ membershipId, location }) {
  return {
    redirectUrl: getRedirectUrl(membershipId, get('state.redirectUrl', location))
  }
}

function onComponentDidMount ({ membershipId, clearMemberSession }) {
  // clear session to prevent old session member details from persisting thru new order
  clearMemberSession()
  if (membershipId) LS.setMembershipId(membershipId)
}

export default compose(
  connectQuery('membershipId'),
  connect(mapStateToProps, mapDispatchToProps),
  modifyProps(modify),
  onMount(onComponentDidMount),
)(MemberLogin)
