import React, { Component } from 'react'
import orderBy from 'lodash/orderBy'
import get from 'lodash/fp/get'

// A HOC that supplies a sort function to its child. 
// Given a sortPath, this function will compare the properties of two objects at that path.

export default function sortable (options={}) {
  const { ascending=true, sortFunc, sortPath } = options
  return function (WrappedComponent) {
    class Sortable extends Component {
      constructor () {
        super()
        this.state = { ascending, sortFunc, sortPath }
        this.setAscending = this.setAscending.bind(this)
        this.setDescending = (val) => this.setAscending(!val)
        this.setSortFunc = this.setSortFunc.bind(this)
        this.setSortPath = this.setSortPath.bind(this)
      }
      setAscending (ascending) {
        this.setState({ ascending })
      }
      setSortFunc (sortFunc) {
        this.setState({ sortFunc })
      }
      setSortPath (newPath, doToggle=true) {
        // By default, toggles ascending if the path is already selected
        let { sortPath, ascending } = this.state
        if (newPath === sortPath) {
          if (doToggle) ascending = !ascending
        } else {
          // Default to ascending when switching paths
          ascending = true
        }
        this.setState({ sortPath: newPath, ascending })
      }
      sort (array) {
        const { ascending, sortFunc, sortPath } = this.state
        // Use custom sort if provided, otherwise default to orderBy()
        if (sortFunc) {
          const sorted = [...array].sort(sortFunc)
          if (!ascending) sorted.reverse()
          return sorted
        } else {
          const order = ascending ? 'asc' : 'desc'
          const sorted = orderBy(array, item => get(sortPath, item), order)
          return sorted
        }
      }
      render () {
        const { ascending, sortPath } = this.state
        return (
          <WrappedComponent 
            { ...{ 
              sort: this.sort.bind(this), 
              ascending, 
              descending: !ascending,
              sortPath,
              setAscending: this.setAscending,
              setDescending: this.setDescending,
              setSortFunc: this.setSortFunc,
              setSortPath: this.setSortPath, 
              ...this.props 
            }} 
          />
        )
      }
    }
    return Sortable
  }
}