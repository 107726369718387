import React from 'react'
import { compose } from 'redux'
import { reduxForm, propTypes as formPropTypes, FieldArray, getFormValues, } from 'redux-form'
import { SubmitButton } from 'lp-components'
import { modifyProps, } from 'lp-utils'
import PropTypes from 'prop-types'
import { differenceWith, countBy, getOr, groupBy, isEqual, mapFormValuesToArray, set } from 'utils'
import { MembershipQuantitySelect } from '../components'
import { SecondaryMemberDetailForm } from '../forms'
import { getSet, onUpdate, } from 'lp-hoc'
import { connect } from 'react-redux'
import * as LS from 'local-storage'

const propTypes = {
  availableMembers: PropTypes.array,
  orderInfo: PropTypes.object,
  setBillingError: PropTypes.func,
  tickets: PropTypes.array,
  ticketType: PropTypes.string,
  showTermsModal: PropTypes.func,
  ...formPropTypes,
}

const defaultProps = {}

const addOnMemberCountForMembershipType = (addOn, members) => {
  return members[addOn.centamanId].length
}

function AddOnsForm ({
  availableMembers,
  handleSubmit,
  submitting,
  children,
  options,
  addOnMembers,
  setAddOnMembers,
}) {
  return (
    <div>
      <form name="add-on-detail" onSubmit={ handleSubmit }>
        <div className="cc-form">
          <h4 className="billing-header">Add Ons</h4>
          { options.map((addOn, i) => {
            return (
              <div className="add-on" key={ i }>
                <div className="billing-form">
                    <div className="image-button-left">
                      <img src={ addOn.membershipImage } />
                      {
                        addOnMemberCountForMembershipType(addOn, addOnMembers) === 0 ?
                        <a
                          className="button-chill"
                          onClick={ (e) => {
                            e.preventDefault()
                            setAddOnMembers({
                              ...addOnMembers,
                              [addOn.centamanId]: [{ membershipTypeId: addOn.centamanId }],
                            })
                          }}>
                            Add
                        </a>
                        :
                        <a
                          className="button-chill"
                          onClick={ (e) => {
                            e.preventDefault()
                            setAddOnMembers({
                              ...addOnMembers,
                              [addOn.centamanId]: [],
                            })
                          }}>
                            Remove
                        </a>
                      }
                    </div>
                    <div className="add-on-copy">
                      <h5>{ addOn.displayName }</h5>
                      <p>{ addOn.description }</p>
                    </div>
                    <div>
                      {
                        addOnMemberCountForMembershipType(addOn, addOnMembers) > 0 &&
                        <MembershipQuantitySelect
                          membership={ addOn }
                          includedQuantity={ addOn.maxQuantity }
                          additionalQuantityPrice={ addOn.price }
                          value={ addOnMemberCountForMembershipType(addOn, addOnMembers) }
                          updateQuantity={ (value, offset) => {
                            const members = offset > 0 ? addOnMembers[addOn.centamanId].concat([{ membershipTypeId: addOn.centamanId }]) : addOnMembers[addOn.centamanId].slice(0, addOnMembers[addOn.centamanId].length - 1)
                            setAddOnMembers({
                              ...addOnMembers,
                              [addOn.centamanId]: members,
                            })
                          }}
                        />
                      }
                      <FieldArray
                        name={ `addOnMembers[${addOn.centamanId}]` }
                        component={ SecondaryMemberDetailForm }
                        props={{
                          availableMembers,
                          totalQty: addOnMemberCountForMembershipType(addOn, addOnMembers),
                          title: 'Additional Family Member',
                          secondaryMembershipTypeId: addOn.centamanId,
                        }}
                      />
                    </div>
                  </div>
                </div>
                )
              })}

          { children }
          <SubmitButton
            submitting={ submitting }
            className="button-primary"
          >
            Continue
          </SubmitButton>
        </div>
      </form>
    </div>
  )
}

AddOnsForm.propTypes = propTypes
AddOnsForm.defaultProps = defaultProps

// Map options into form values with the expectation that a membership type with no members added will be represented by an empty array
function mapMembersByIdToFormValues (options, addOnMembersById) {
  return options.reduce((formValues, option) => {
    return set(
      option.centamanId,
      getOr([], option.centamanId, addOnMembersById),
      formValues
    )
  }, {})
}

// Adds all secondary and add on members together based on current form values or local storage as a backup
function getSelectedMembers ({ checkout, formValues, savedAddOnMembers }) {
  const selectedSecondaryMembers = getOr([], 'secondaryMembers', checkout)
  const selectedAddOnMembers = (formValues && formValues.addOnMembers)
    ? mapFormValuesToArray(formValues.addOnMembers)
    : mapFormValuesToArray(savedAddOnMembers)
  
  return [ ...selectedSecondaryMembers, ...selectedAddOnMembers ]
}

function modify ({ membership, options, formValues, secondaryMembers }) {
  if (!membership) return {}
  const checkout = LS.getCheckout()
  
  const savedAddOnMembers = getOr({}, 'addOnMembers', checkout)
  // If addOnMembers exist as an array, then map them into an object by id to match the form setup
  const addOnMembersById = !Array.isArray(savedAddOnMembers)
    ? savedAddOnMembers
    : groupBy(savedAddOnMembers, 'membershipTypeId')
  const selectedMembers = getSelectedMembers({ checkout, formValues, savedAddOnMembers })
  
  return {
    initialValues: {
      addOnMembers: mapMembersByIdToFormValues(options, addOnMembersById),
    },
    // Look at all members already added to the order and added to the current form (incl. secondaryOptions)
    availableMembers: differenceWith(
      secondaryMembers,
      selectedMembers,
      (member, selectedMember) => member.id === selectedMember.id
    )
  }
}

function mapStateToProps (state) {
  return {
    formValues: getFormValues('add-on-detail')(state),
  }
}

function onComponentDidUpdate ({ formValues, updateCheckout, }, oldProps) {
  if (!formValues || !oldProps.formValues) return

  const addOnMemberCount = countBy(mapFormValuesToArray(formValues.addOnMembers), 'id')
  const oldAddOnMemberCount = countBy(mapFormValuesToArray(oldProps.formValues.addOnMembers), 'id')
  
  // Update checkout if the number of members changes (or the selected ids change)
  if (!isEqual(addOnMemberCount, oldAddOnMemberCount))
    return updateCheckout(formValues)
}

export default compose(
  connect(mapStateToProps, null),
  modifyProps(modify),
  getSet(['addOnMembers']),
  reduxForm({
    form: 'add-on-detail',
  }),
  onUpdate(onComponentDidUpdate),
)(AddOnsForm)
