import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

const propTypes = {
  membership: PropTypes.object.isRequired,
  backButton: PropTypes.bool,
}

function DetailHeader ({
  membership,
  backButton,
}) {
  return (
    <div className="choose-memberships-top">
        { backButton &&
          <Link className="back-button" to={ `/memberships/${membership.id}` }>
            { '< ' } Back
          </Link>
        }
      <h4>{ membership.displayName }</h4>
    </div>
  )
}

DetailHeader.propTypes = propTypes

export default DetailHeader

