import React, { useState } from "react";
import exact from "prop-types-exact";
import { InputLabel } from "lp-components";
import { TermsModal } from "components";

const propTypes = {};

const defaultProps = {};

function Terms({ props }) {
  const [showTermsModal, setShowTermsModal] = useState(false);
  return (
        <InputLabel {...props}>
        I have read and agreed to the{" "}
        <button type="button" className="link-primary" onClick={() => setShowTermsModal(true)}>
            Terms and Conditions
        </button>{" "}
        *
        {showTermsModal && (
            <TermsModal handleHide={() => setShowTermsModal(false)}/>
        )}
        </InputLabel>
  )
  
}

Terms.propTypes = exact(propTypes);
Terms.defaultProps = defaultProps;

export default React.memo(Terms);
