import { toDateString } from "utils/date-formatting-utils";

// Get Current Date
const currentDate = new Date();
const timeSlots = ["10:00", "12:00", "14:00", "16:00", "18:00"];
const displayTimeSlots = ["10:00", "12:00", "2:00", "4:00", "6:00"];

// Create an Array to set Dynamic Calendar Dates
const dynamicCalendarDates = [];

// Create a Collection of Dates that are Sold Out
const soldOutDateIndexCollection = getRandomSoldOutDateIndices(105);

// Generate each entry for the Stubbed Data
for (let i = 0; i < 105; i++) {
  const currentDateTime = new Date(currentDate);
  currentDateTime.setDate(
    currentDate.getDate() + Math.floor(i / timeSlots.length)
  );

  const currentSlot = timeSlots[i % timeSlots.length];
  const currentDisplaySlot = displayTimeSlots[i % displayTimeSlots.length];
  const setAMorPM = i % displayTimeSlots.length === 0 ? "am" : "pm";
  const offsetDate = new Date(currentDateTime);
  const offsetDateToString = toDateString(offsetDate);

  const newObj = {
    activityId: 36,
    bookingTypeId: 59,
    capacity: 280,
    centamanId: i,
    description: `Weekday Winter Tubing ${currentDisplaySlot}${setAMorPM}-${currentDisplaySlot === "12:00" ? 2 : parseInt(currentDisplaySlot) + 2
      }:00pm`,
    displayEndTime: `${currentDisplaySlot === "12:00" ? 2 : parseInt(currentDisplaySlot) + 2
      }:00pm`,
    displayName: `Weekday Winter Tubing ${currentDisplaySlot}${setAMorPM}-${currentDisplaySlot === "12:00" ? 2 : parseInt(currentDisplaySlot) + 2
      }:00pm`,
    displayStartTime: `${currentDisplaySlot}${setAMorPM}`,
    endDate: offsetDateToString,
    endDatetime: `${offsetDateToString}T${parseInt(currentSlot) + 2
      }:00:00.000+00:00`,
    endTime: `${parseInt(currentSlot) + 2}:00`,
    id: i + 1,
    isYearRound: false,
    name: `Weekday Winter Tubing ${currentDisplaySlot}${setAMorPM}-${currentDisplaySlot === "12:00" ? 2 : parseInt(currentDisplaySlot) + 2
      }:00pm`,
    price: getRandomPrice(),
    soldOut: soldOutDateIndexCollection.includes(i) ? true : isSoldOut(),
    startDate: offsetDateToString,
    startDatetime: `${offsetDateToString}T${currentSlot}:00.000+00:00`,
    startTime: `${currentSlot}`,
    type: "TimedActivityTime",
    vacancy: 260,
    validDate: `${offsetDateToString}T${currentSlot}:00.000+00:00`,
    validDescription: getValidDescription(offsetDate),
    validThrough: `${offsetDateToString}T${parseInt(currentSlot) + 2
      }:00:00.000+00:00`,
  };

  dynamicCalendarDates.push(newObj);
}

// Creates Dynamic Valid Description Field
function getValidDescription(date) {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const dayOfWeek = daysOfWeek[date.getDay()];
  return `${dayOfWeek}, ${date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })} at ${date.toLocaleTimeString([], {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  })}`;
}

// Creates Random Price for Time
function getRandomPrice() {
  const minPrice = 30;
  const maxPrice = 80;
  const priceOptions = [];

  for (let price = minPrice; price <= maxPrice; price += 5) {
    priceOptions.push(price);
  }

  const randomIndex = Math.floor(Math.random() * priceOptions.length);
  return priceOptions[randomIndex];
}

// Determine Random Sold Out Boolean
function isSoldOut() {
  const threshold = 0.6; // 60% chance of being false (not sold out)
  return Math.random() > threshold;
}

// Determine Random Sold Out Dates
function getRandomSoldOutDateIndices(size) {
  const indexCollection = [];

  while (indexCollection.length < 25) {
    const startIndex = Math.floor(Math.random() * (size / 5)) * 5; // Ensure at least 5 elements remaining
    for (let i = 0; i < 5; i++) {
      const newIndex = startIndex + i;

      if (!indexCollection.includes(newIndex)) {
        indexCollection.push(newIndex);
      }
    }
  }

  return indexCollection;
}

export const fetchAvailableCalendarDates = dynamicCalendarDates;

const dynamicTicketedDates = dynamicCalendarDates.map((date) => {
  const template = {
    activity_id: date.activityId,
    booking_type_id: date.bookingTypeId,
    capacity: date.capacity,
    centaman_id: date.centamanId,
    description: date.description,
    display_end_time: date.displayEndTime,
    display_name: date.displayName,
    display_start_time: date.displayStartTime,
    end_date: date.endDate,
    end_datetime: date.endDatetime,
    end_time: date.endTime,
    id: date.id,
    is_year_round: date.isYearRound,
    name: date.name,
    old_out: false,
    start_date: date.startDate,
    start_datetime: date.startDatetime,
    start_time: date.startTime,
    type: date.type,
    vacancy: date.vacancy,
    valid_date: date.validDate,
    valid_description: date.validDescription,
    valid_through: date.validThrough,
    tickets: [
      {
        activityId: date.activityId,
        activityTimeId: date.id,
        addOn: false,
        categoryName: null,
        centamanId: date.centamanId,
        costRateId: 1000001,
        description: date.description,
        displayName: date.displayName,
        eventId: 3374168,
        general: false,
        id: date.id,
        position: 1000,
        price: date.price,
        timed: true,
        type: "TimedTicket",
      },
    ],
  };

  return template;
});

export const fetchDynamicTickets = dynamicTicketedDates;
