import React from 'react'
import PropTypes from 'prop-types'
import { Field, } from 'redux-form'
import {
  Input,
  DateInput,
  // HiddenInput,
  Select,
} from 'lp-components'
import {
  generateMemberOptionGroups,
  isRequired,
  set,
  stripTimezone,
} from 'utils'
import { compose } from 'redux'
import { onUpdate, } from 'lp-utils'
import { NEW_MEMBERSHIP_ID } from 'config'

const propTypes = {
  availableMembers: PropTypes.array,
  fields: PropTypes.object,
  title: PropTypes.string,
  secondaryMembershipTypeId: PropTypes.number,
}

function SecondaryMemberDetailForm ({ fields, title, secondaryMembershipTypeId, availableMembers }) {
  return (
    <div>
      {
        fields.map((member, i) => {
          const currentMember = fields.get(i)
          
          return (
          <div key={i} className="udf-details">
            <h4>{ `${title || 'Family Member'} #${i + 1}` }</h4>
            <div className="billing-details">
              <Field
                name={ `${member}.id` }
                label="Selection"
                component={ Select }
                placeholder="Choose an option"
                optionGroups={ generateMemberOptionGroups(currentMember, availableMembers) }
                validate={ isRequired }
                aria-required="true"
                parse={(val) => {
                  if (isNaN(val)) return val
                  return Number(val) // for strict equality comparison with availableMembers
                }}
                onChange={(e, val) => {
                  // Replace when selecting new after already populated by an existing member
                  if (val === NEW_MEMBERSHIP_ID && currentMember.id) {
                    fields.remove(i)
                    fields.insert(i, {
                      id: NEW_MEMBERSHIP_ID,
                      membershipTypeId: secondaryMembershipTypeId
                    })
                    
                    return
                  }
                  
                  // Add the selected member
                  const member = availableMembers.find(({ id }) => id === Number(val))
                  fields.remove(i)
                  fields.insert(i, set('membershipTypeId', secondaryMembershipTypeId, member))
                }}
              />
            
              { currentMember.id === NEW_MEMBERSHIP_ID &&
                <React.Fragment>
                  <Field
                    name={`${member}.firstName`}
                    component={ Input }
                    validate={ isRequired }
                  />
                  <Field
                    name={`${member}.lastName`}
                    component={ Input }
                    validate={ isRequired }
                  />
                  <Field
                    name={`${member}.dateOfBirth`}
                    component={ DateInput }
                    validate={ isRequired }
                    autoComplete="off"
                    format={ stripTimezone }
                    placeholderText="MM/DD/YYYY"
                  />
                </React.Fragment>
              }
            </div>
         </div>)
      })
    }
  </div>
  )
}

const onComponentUpdate = ({ totalQty, secondaryMembershipTypeId, fields, }, previousProps) => {
  if (totalQty === previousProps.totalQty) return
  if (totalQty === 0) fields.removeAll()
  if (totalQty > previousProps.totalQty) fields.push({
    membershipTypeId: secondaryMembershipTypeId
  })
  if (totalQty < previousProps.totalQty) fields.pop()
}

SecondaryMemberDetailForm.propTypes = propTypes

export default compose(
  onUpdate(onComponentUpdate),
)(SecondaryMemberDetailForm)
