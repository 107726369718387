import { SubmissionError } from 'redux-form'

export const CENTAMAN_ERROR = {
  title: 'Unable to Process Request',
  message: 'It seems the ticketing system is unavailable right now. Please refresh and try again in a few minutes. If you continue to experience problems, contact shop@uolf.org.'
}

export const TICKET_QTY_ERROR = {
  title: 'Invalid Selection',
  message: 'The selected number of tickets are not available. Please edit your selection and try again.'
}

export const VALIDATION_ERROR = {
  title: 'Invalid Form Fields',
  message: 'Complete all required fields and try again.'
}

export const ADD_ON_QUANTITY_EXCEED_ERROR = {
  title: 'Invalid Selection',
  message: 'Add-on ticket quantities may not exceed the total number of selected passes. Please edit your add-on selections and try again.'
}

export function vacancyFormError () {
  throw new SubmissionError(TICKET_QTY_ERROR)
}

export function centamanFormError () {
  throw new SubmissionError(CENTAMAN_ERROR)
}

export function validationFormError () {
  throw new SubmissionError(VALIDATION_ERROR)
}

export function addOnQtyExceededError () {
  throw new SubmissionError(ADD_ON_QUANTITY_EXCEED_ERROR)
}

export function throwAppError ({ title, message }) {
  throw new SubmissionError({ title, message })
}
