import moment from 'moment-timezone'
import stripTimezone from './strip-timezone'

const timeNow = moment() // will be in browser timezone

function isSoldOut (activityTime, activity) {
  const startingTime = stripTimezone(activityTime.validDate)
  const utahStartingTime = moment.tz(startingTime, 'America/Denver').format() // in mountain timezone
  const utahSellThroughTime = moment(utahStartingTime).subtract({ hours: activity.cutoffHours })
  return activityTime.vacancy <= 0 || Date.parse(timeNow) >= Date.parse(utahSellThroughTime)
}

export default isSoldOut
