import React from 'react'
import { compose } from 'redux'
import { Field, propTypes as formPropTypes, change } from 'redux-form'
import { lpForm } from 'lp-form'
import { push } from 'react-router-redux';
import { LoadingIndicator, Terms, RecaptchaInput } from 'components'
import { onLoad, modifyProps } from 'lp-utils'
import { Input, SubmitButton, Checkbox } from 'lp-components'
import * as actions from '../actions'
import PropTypes from 'prop-types'
import {
  CENTAMAN_ERROR,
  VALIDATION_ERROR,
  scrollToTop,
  set,
  trackPurchaseFrom
} from 'utils'
import * as LS from 'local-storage'
import { get } from 'lodash'

const propTypes = {
  hidePhoneNumber: PropTypes.bool,
  orderInfo: PropTypes.object,
  setBillingError: PropTypes.func,
  tickets: PropTypes.array,
  ticketType: PropTypes.string,
  showTermsModal: PropTypes.func,
  skipPaymentDetails: PropTypes.bool,
  ...formPropTypes,
}

const defaultProps = {
  hidePhoneNumber: false
}

const validateRecaptcha = (value) => {
  // Value will subsequently be validated server-side during order submission
  if (!value) return 'reCAPTCHA must be verified'
}

function BillingForm(
    {
      handleSubmit,
      hidePhoneNumber,
      submitting,
      skipPaymentDetails,
      orderInfo,
      change
    }) {
  return (
      <div>
        <form onSubmit={(e, vals) => {
          e.preventDefault()
          change('donationAmount', orderInfo.donationAmount)
          handleSubmit(vals)
        }}>
          <div className="cc-form">
            <h4 className="billing-header">{skipPaymentDetails ? 'Contact Details' : 'Billing Details'}</h4>
            <div className="billing-details">
              <div className="billing-form">
                <Field
                    name="firstName" component={Input}
                    className="input-short input-short"
                    label={skipPaymentDetails ? "First Name" : "First Name (as shown on card) *"}
                />
                <Field
                    name="lastName" component={Input}
                    className="input-short input-short-right"
                    label={skipPaymentDetails ? "Last Name" : "Last Name (as shown on card) *"}
                />
                {!skipPaymentDetails &&
                    <React.Fragment>
                      <Field
                          name="creditCard"
                          component={Input}
                          label="Credit Card Number *"
                      />
                      <Field
                          name="expMonth"
                          component={Input}
                          className="input-short"
                          label="Expiration Month (MM) *"
                      />
                      <Field
                          name="expYear"
                          component={Input}
                          className="input-short input-short-right"
                          label="Expiration Year (YYYY) *"
                      />
                      <Field
                          name="verificationCode"
                          component={Input}
                          className="input-short"
                          label="Security Code*"
                      />
                      <p className="input-copy">3 to 4 digit number typically located on the back of your card</p>
                    </React.Fragment>
                }
                <Field
                    name="email"
                    component={Input}
                    label="Email (confirmation will be sent to this address) *"
                />
                {!hidePhoneNumber &&
                    <Field
                        name="phone"
                        component={Input}
                        label="Phone *"
                    />
                }
                <label style={{marginTop: 25 + 'px'}}>* denotes a required field</label>
              </div>
            </div>
            <div className="checkbox terms">
              <Field
                  name="termsAgreed"
                  component={Checkbox}
                  labelComponent={Terms}
              />
            </div>
            <Field
                name="recaptcha"
                label={false}
                component={RecaptchaInput}
                recaptchaSiteKey={process.env.GOOGLE_RECAPTCHA_CLIENT_KEY}
                validate={validateRecaptcha}
            />
            <hr/>
            <SubmitButton
                submitting={submitting}
                className="button-primary">
              Submit Order
            </SubmitButton>
          </div>
        </form>

      </div>
  )
}

BillingForm.propTypes = propTypes
BillingForm.defaultProps = defaultProps

const orderSuccess = ({response, dispatch, props: {orderInfo, tickets, ticketType}}) => {
  const {token: orderToken, receipt, coupon} = response.data.attributes

  // Membership Order
  if (response && get(response, 'data.type') === 'membership_order') {
    LS.clearMembershipOrderDetails()
    return dispatch(push(`/memberships/confirmation/${orderToken}`))
  }

  // Ticketing Order
  sendTransactionToGoogle({transactionId: receipt, orderInfo, tickets, ticketType, coupon})
  dispatch(push(`/confirmation/${orderToken}`))
  return dispatch(actions.clearOrderInfo())
}

const orderError = ({dispatch, submitError, props: {setBillingError}}) => {
  handleOrderError(submitError, dispatch, setBillingError)
}

function handleOrderError(error, dispatch, setBillingError) {
  scrollToTop()
  dispatch(change('coupon-form', 'coupon', null))
  if (error && error.errors && error.errors.title) return setBillingError(error.errors)
  if (error && error.spreedly) return setBillingError(error)
  if (!error) return setBillingError(VALIDATION_ERROR)
  setBillingError(CENTAMAN_ERROR)
}

function sendTransactionToGoogle({transactionId, orderInfo, tickets, ticketType, coupon}) {
  const total = orderInfo.orderTotal || orderInfo.total
  trackPurchaseFrom(transactionId, total, coupon, ticketType, tickets)
}

function modify({initialValues}) {
  return {
    initialValues: set('termsAgreed', false, initialValues || {}),
    onSubmitSuccess: (response, dispatch, props) => orderSuccess({response, dispatch, props}),
    onSubmitFail: (error, dispatch, submitError, props) => orderError({error, dispatch, submitError, props}),
  }
}

export default compose(
    modifyProps(modify),
    lpForm({
      name: 'billing-form',
      constraints: {
        'firstName': {
          presence: true,
          length: {maximum: 29}
        },
        'lastName': {
          presence: true,
          length: {maximum: 30}
        },
        'creditCard': {
          presence: true,
          numericality: {
            onlyInteger: true,
          }
        },
        'expMonth': {
          presence: {
            message: "^Expiration month can't be blank"
          },
          numericality: {
            onyInteger: true,
            lessThanOrEqualTo: 12,
            greaterThan: 0,
          },
          length: {
            is: 2,
          }
        },
        'expYear': {
          presence: {
            message: "^Expiration year can't be blank"
          },
          numericality: {
            onlyInteger: true,
          },
          length: {
            is: 4,
          }
        },
        'verificationCode': {
          presence: {
            message: "^Security code can't be blank"
          },
          numericality: {
            onlyInteger: true,
          },
          length: {
            minimum: 3
          }
        },
        'email': {
          presence: true,
          email: true,
          length: {maximum: 60}
        },
        'phone': {
          presence: true,
          length: {maximum: 20}
        },
        'termsAgreed': {
          presence: {
            message: '^You must accept the terms and conditions',
          },
          inclusion: {
            within: [true],
            message: '^You must accept the terms and conditions',
          },
        }
      }
    }),
    onLoad(({submitting}) => !submitting, LoadingIndicator),
)(BillingForm)
