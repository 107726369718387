import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
    to: PropTypes.string.isRequired,
    target: PropTypes.string,
    className: PropTypes.string,
}

const defaultProps = {
    target: '_blank',
}

function ExternalLink ({ to, className, target, children, ...rest }) {
    return (
        <a href={to} target={target} rel="noopener noreferrer" className={className} {...rest}>
            {children}
        </a>
    )
}

ExternalLink.propTypes = propTypes
ExternalLink.defaultProps = defaultProps

export default React.memo(ExternalLink)