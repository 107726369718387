import { memoize, size } from 'lodash'

export const isRequired = value => (value ? undefined : 'Required')

export const isNumber = value =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined

export const validEmail = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined

export const validName = value => (size(value) > 30)
  ? 'Cannot exceed maximum character limit of 30'
  : undefined

// Memoize to prevent react from continually recreating the validate function on render
export const createUdfValidation = memoize((udf) => {
  return function validateUdf (value) {
    if (
      udf.isRequired &&
      (value === undefined || value === null || value === '')
    )
      return 'Required'

    if (udf.fieldLength && size(value) > udf.fieldLength)
      return `Cannot exceed maximum character limit of ${udf.fieldLength}`

    if (udf.fieldType === 'numeric' && value) return isNumber(value)
  }
})
