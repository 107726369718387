import React from 'react'
import { compose } from 'redux'
import { Field, propTypes as formPropTypes, FormSection, getFormValues, } from 'redux-form'
import { LoadingIndicator } from 'components'
import { onLoad, modifyProps, } from 'lp-utils'
import { Input, DateInput, SubmitButton, Select, HiddenInput, } from 'lp-components'
import PropTypes from 'prop-types'
import {
  get,
  isEmpty,
  regionData,
  removeInvalidDates,
  stripTimezone,
} from 'utils'
import { first, } from 'lodash'
import { onUpdate } from 'lp-hoc'
import { connect } from 'react-redux'
import { lpForm } from 'lp-form'

const propTypes = {
  orderInfo: PropTypes.object,
  setBillingError: PropTypes.func,
  tickets: PropTypes.array,
  ticketType: PropTypes.string,
  hasListOfStates: PropTypes.bool.isRequired,
  stateOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  ...formPropTypes,
}

const defaultProps = {}

function PrimaryMembershipDetailsForm ({
  handleSubmit,
  submitting,
  children,
  hasListOfStates,
  membershipTypeOptions,
  preSelectedMembershipType,
  stateOptions,
}) {
  return (
    <div>
      <form name="membership-detail" onSubmit={ handleSubmit }>
        <div className="cc-form">
          <h4 className="billing-header">Account Holder Details</h4>
          <div className="billing-details individual-membership">
            <div className="billing-form">
              <FormSection name="primaryMember">
                { preSelectedMembershipType ?
                  <Field
                    name="membershipTypeId"
                    component={ HiddenInput }
                  />
                  :
                  <Field
                    name="membershipTypeId"
                    label="Membership Types"
                    component={ Select }
                    options={ membershipTypeOptions }
                  />
                }
                <Field
                  name="firstName"
                  component={ Input }
                  label="First Name"
                />
                <Field
                  name="lastName"
                  component={ Input }
                  label="Last Name"
                />
                <Field
                  name="dateOfBirth"
                  component={ DateInput }
                  label="Date of Birth"
                  format={ stripTimezone }
                  autoComplete="off"
                  placeholderText="MM/DD/YYYY"
                />
                <Field
                  name="phone"
                  label="Phone Number"
                  component={ Input }
                  type="tel"
                />
                <FormSection name="address">
                  <Field
                    name="country"
                    component={ Select }
                    placeholder="Select Country"
                    options={ regionData }
                  />
                  <Field
                    name="streetAddress1"
                    label="Address"
                    component={ Input }
                  />
                  <Field
                    name="city"
                    component={ Input }
                    className="input-short"
                  />
                  { hasListOfStates &&
                    <Field
                      name="state"
                      component={ Select }
                      className="input-short input-short-right"
                      placeholder="Select State"
                      options={ stateOptions }
                    />
                  }
                  { !hasListOfStates &&
                    <Field
                      name="state"
                      component={ Input }
                      className="input-short input-short-right"
                    />
                  }
                  <Field
                    name="zip"
                    component={ Input }
                    className="input-short"
                  />
                </FormSection>
              </FormSection>
            </div>
          </div>
          { children }
          <SubmitButton
            submitting={ submitting }
            className="button-primary">
            Continue
          </SubmitButton>
        </div>
      </form>
    </div>
  )
}

PrimaryMembershipDetailsForm.propTypes = propTypes
PrimaryMembershipDetailsForm.defaultProps = defaultProps

function findStates (selectedCountry) {
  if (!selectedCountry) return []
  const country = regionData.find(data => data.value === selectedCountry.toUpperCase())
  return country ? country.states : []
}

function modify ({ options, initialValues, selectedCountry }) {
  const sanitizedInitialValues = removeInvalidDates(initialValues, 'dateOfBirth')
  const stateOptions = findStates(selectedCountry)
  
  return {
    membershipTypeOptions: options.map((option) => {
      return { key: `${option.displayName} - $${option.price}`, value: option.centamanId }
    }),
    preSelectedMembershipType: options.length == 1,
    initialValues: {
      primaryMember: {
        membershipTypeId: options.length === 1 ? first(options).centamanId : null,
        ...sanitizedInitialValues.primaryMember,
        address: {
          ...get('primaryMember.address', sanitizedInitialValues),
          country: get('primaryMember.address.country', sanitizedInitialValues) || 'UNITED STATES'
        }
      },
      secondaryMembers: (sanitizedInitialValues.secondaryMembers || []).filter((member) => !member.addOn),
    },
    hasListOfStates: !isEmpty(stateOptions),
    stateOptions,
  }
}

function mapStateToProps(state) {
  const formValues = getFormValues('membership-detail')(state)
  return {
    formValues,
    selectedCountry: get('primaryMember.address.country', formValues),
  }
}

function onComponentDidUpdate ({ formValues, updateCheckout, }, oldProps) {
  if (!formValues || !oldProps.formValues) return
  if (formValues.secondaryMembers.length !== oldProps.formValues.secondaryMembers.length) updateCheckout(formValues)
  if (!formValues.primaryMember) return
  if (formValues.primaryMember.membershipTypeId !== oldProps.formValues.primaryMember.membershipTypeId) updateCheckout(formValues)
}

export default compose(
  connect(mapStateToProps, null),
  modifyProps(modify),
  lpForm({
    name: 'membership-detail',
    enableReinitialize: true,
    constraints: {
      'primaryMember.firstName': { presence: true, length: { maximum: 30 }},
      'primaryMember.lastName': { presence: true, length: { maximum: 30 }},
      'primaryMember.dateOfBirth': { presence: true },
      'primaryMember.phone': {
        presence: {
          message: "^Phone number can't be blank"
        },
        length: { maximum: 20 }
      },
      'primaryMember.address.streetAddress1': {
        presence: {
          message: "^Address can't be blank"
        },
        length: { maximum: 50 }
      },
      'primaryMember.address.city': { presence: true, length: { maximum: 30 }},
      'primaryMember.address.state': { presence: true, length: { maximum: 30 }},
      'primaryMember.address.zip': { presence: true, length: { maximum: 10 }},
      'primaryMember.address.country': { presence: true },
    }
  }),
  onLoad(({ submitting }) => !submitting, LoadingIndicator),
  onUpdate(onComponentDidUpdate),
)(PrimaryMembershipDetailsForm)
