import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { logException } from 'sentry'
import { onError } from 'lp-hoc'
import { getFlashMessages, flashMessageType } from 'redux-flash'
import { FlashMessageContainer } from 'lp-components'
import { Footer } from 'components'
import Header from './header/header'
import { selectors as orderSelectors } from './orders/reducer'

const propTypes = {
  flashMessages: PropTypes.arrayOf(flashMessageType).isRequired,
  cart: Types.cart,
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired
}

const defaultProps = {
  cart: null
}

function Layout ({ flashMessages, cart, children, location }) {
  const currentFlow = location.pathname.includes(Types.ACTIVITY_PATH_PREFIX) ? Types.ACTIVITY_PATH_PREFIX : Types.MEMBERSHIP_PATH_PREFIX
  return (
    <div>
      <Header cart={cart} currentFlow={currentFlow}/>
      <FlashMessageContainer messages={ flashMessages } />
      { children }
      <Footer />
    </div>
  )
}

Layout.propTypes = propTypes

Layout.defaultProps = defaultProps

function onComponentDidCatch (props, error, errorInfo) {
  return logException(error, errorInfo)
}

function mapStateToProps (state) {
  return {
    flashMessages: getFlashMessages(state),
    cart: orderSelectors.cart(state)
  }
}

const mapDispatchToProps = {}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  onError(onComponentDidCatch),
)(Layout)
