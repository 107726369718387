import React from 'react'
import PropTypes from 'prop-types'
import NavItem from './nav-item'
import ResponsiveNavDropdown from './responsive-nav-dropdown'
import { Link } from 'react-router'
import { compose } from 'redux'
import { triggerResponsiveNav } from 'utils'
import { onMount } from 'lp-utils'

const propTypes = {
  items: PropTypes.array.isRequired,
}

// The mount functionality is dependent on jQuery and Modernizr being included in the head of index.html
function ResponsiveNav ({ items }) {
  return (
    <div className="resp-nav">
      <div className="demo-1">
        <div id="dl-menu" className="dl-menuwrapper">
          <button className="dl-trigger"></button>
          <ul className="dl-menu">
            { items.map((item, i) => {
              return item.children
                ? <ResponsiveNavDropdown key={ i } item={ item } />
                : <NavItem key={ i } item={ item } />
              })
            }
            <li className="cta">
              <Link to="/activities" className="responsive tickets">Purchase Tickets</Link>
            </li>
            <li className="dropdown-cart-link">
              <Link to="/activities/cart" className="responsive tickets">View Cart</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

ResponsiveNav.propTypes = propTypes

export default compose(
  onMount(triggerResponsiveNav),
)(ResponsiveNav)
