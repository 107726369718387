import React from 'react'
import PropTypes from 'prop-types'
import { compose } from "recompose"
import { lpForm } from "lp-form"
import { Field } from "redux-form"
import { Input, SubmitButton } from 'lp-components'

const propTypes = {
  handleSubmit: PropTypes.func.isRequired
}

const defaultProps = {}

function AddOnDonationForm ({ handleSubmit }) {
  return (
    <form onSubmit={handleSubmit}>
      <div className="cc-form donate-today">
        <h1 className="h4 billing-header">
          Donate Today!
        </h1>
        <div className="billing-details">
          <p>Would you like to donate to the Utah Olympic Legacy Foundation today?</p>
          <Field
            name="donationAmount"
            label="Donation Amount"
            component={Input}
            className="donation-input"
          />
          <SubmitButton className="button-donation">Apply</SubmitButton>
        </div>
      </div>
    </form>
  )
}

AddOnDonationForm.propTypes = propTypes
AddOnDonationForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'add-on-donation-form',
    constraints: {
      'donationAmount': { 
        numericality: true
       }
    },
    submitOnChange: false,
  })
)(AddOnDonationForm)
