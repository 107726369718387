import React from "react";
import PropTypes from "prop-types";
import PriceDetail from "./price-detail";
import { FormattedNumber } from "react-intl";

const propTypes = {
  activity: PropTypes.object.isRequired,
  activityTime: PropTypes.object,
  orderInfo: PropTypes.object.isRequired,
  coupon: PropTypes.string,
  couponDescription: PropTypes.array,
  isTimedActivity: PropTypes.bool,
  tickets: PropTypes.array,
  extras: PropTypes.array,
  removeDonation: PropTypes.func.isRequired,
};

function BillingDetails({
  activity,
  activityTime,
  orderInfo: { orderTotal, total, donationAmount },
  tickets,
  isTimedActivity,
  coupon,
  couponDescription,
  extras,
  removeDonation,
}) {
  const isFree = !(total || orderTotal) || total <= 0 || orderTotal <= 0;
  return (
    <div>
      <h4 className="billing-header">Purchase Details</h4>
      <div className="billing-details">
        <img
          src={activity.thumbnail}
          alt={`${activity.displayName} Thumbnail`}
        />
        {isTimedActivity ? (
          <div className="ticket-info">
            <h5>{activity.displayName}</h5>
            <p>{activity.location}</p>
            <p>{activityTime.validDescription}</p>
          </div>
        ) : (
          <div className="ticket-info">
            {activity.showSeason ? (
              <h5>
                {activity.displayName} - {activityTime.nameForDisplay}
              </h5>
            ) : (
              <h5>{activity.displayName}</h5>
            )}
            <p>{activity.location}</p>
            {activityTime && <p>{activityTime.description}</p>}
          </div>
        )}

        <hr />

        {tickets.map((ticket, i) => {
          return (
            <PriceDetail
              key={i}
              price={ticket.pricePer}
              total={ticket.totalPrice}
              displayTitle={ticket.displayTitle}
            />
          );
        })}

        {(extras || []).map((extra, i) => {
          return (
            <PriceDetail
              key={i}
              price={extra.pricePer}
              total={extra.totalPrice}
              displayTitle={extra.displayTitle}
            />
          );
        })}

        {donationAmount > 0 && (
          <PriceDetail
            price={parseFloat(donationAmount)}
            total={parseFloat(donationAmount)}
            displayTitle="Donation to UOLF"
            removeItem={() => {
              removeDonation();
            }}
          />
        )}

        {coupon && couponDescription && couponDescription.length > 0 && (
          <div>
            {couponDescription.map((discount, i) => {
              return (
                <div key={i}>
                  <p className="discount-description">{discount}</p>
                  <hr />
                </div>
              );
            })}
          </div>
        )}

        <div className="ticket-type total">
          <p>Total</p>
          <p className="price">
            {isFree ? (
              "$0"
            ) : (
              <FormattedNumber
                value={orderTotal || total}
                style="currency"
                currency="USD"
              />
            )}
          </p>
        </div>
      </div>
    </div>
  );
}

BillingDetails.propTypes = propTypes;

export default BillingDetails;
