import React from "react";
import { PropTypes } from "prop-types";
import classnames from "classnames";
import { pluralize } from "utils";

const propTypes = {
  input: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      soldOut: PropTypes.bool,
    })
  ).isRequired,
  selectedActivityTime: PropTypes.object,
};

const defaultProps = {};

function CalendarRadioGroup({ input, options, selectedActivityTime }) {
  return (
    <fieldset className="activity-time-radio-group">
      {options.map((option) => {
        const isSelected =
          selectedActivityTime &&
          selectedActivityTime.displayStartTime === option.key;
        return (
          <fieldset key={option.value} className="activity-time-radio-group">
            <label
              className={classnames({
                "activity-time-sold-out": option.soldOut,
                selected: isSelected,
              })}
            >
              <div className="input-wrapper">
                <input
                  {...input}
                  type="radio"
                  value={option.value}
                  checked={input.value === option.value.toString()}
                />
              </div>
              <div className="radio-selection">
                <div
                  className={classnames({
                    "sold-out": option.soldOut,
                  })}
                >
                  {option.key}
                </div>
                <span>
                  {option.vacancy > 0
                    ? `${option.vacancy <= 20 ? option.vacancy : "20+"
                    } ${pluralize("spot", option.vacancy)} left`
                    : "sold out"}
                </span>
              </div>
            </label>
          </fieldset>
        );
      })}
    </fieldset>
  );
}

CalendarRadioGroup.propTypes = propTypes;
CalendarRadioGroup.defaultProps = defaultProps;

export default CalendarRadioGroup;
