import React from 'react'
import PropTypes from 'prop-types'
import { venueClass } from 'utils'

const propTypes = {
  item: PropTypes.object.isRequired,
}

function PrimaryNavDropdown ({ item: { title, children } }) {
  return (
    <li className="nav-item nav-item-dropdown">
      <a className="dropdown-trigger" href="#">{ title }</a>
      <ul className="dropdown-menu">
        { children.map((childItem, i) => {
          return (
            <li className="dropdown-menu-item" key={ i }>
              <a  className={ `primary ${ childItem.object } ${ childItem.objectSlug } nav-logo-${ venueClass(childItem.title) }` } 
                  href={ childItem.url } 
                  target="_blank" 
                  rel="noopener noreferrer">
                <p>{ childItem.title }</p>
              </a>
            </li>
          )
        })}
      </ul>
    </li>
  )
}

PrimaryNavDropdown.propTypes = propTypes

export default PrimaryNavDropdown