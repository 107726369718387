import React from 'react'
import PropTypes from 'prop-types'
import { IntlProvider } from 'react-intl'

const propTypes = {
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

function Layout ({ children }) {
  return (
    <IntlProvider locale="en">
      { children }
    </IntlProvider>
  )
}

Layout.propTypes = propTypes

Layout.defaultProps = defaultProps

export default Layout
