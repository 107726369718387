/**
 * @name createFieldValidator
 * @param {String} fieldPath - The path of the field. This can be in dot notation for nested form sections
 * @param {Object|Function} constraints - An object defining constraints for a particular field (uses ValidateJS syntax), or a constraints creator that will be passed the form props.
 * @returns {Array || undefined} - Returns an array of error messages (if any exist), otherwise undefined
 */

import { validateWithOptions } from 'lp-form'
import { get, isFunction } from 'lodash'

function createFieldValidator (fieldPath, constraints, { fullMessages = false, ...options } = {}) {
  return function validateField (value, allValues, props) {
    const constraintsObj = isFunction(constraints) ? constraints(props) : constraints
    const errors = validateWithOptions(
      { [fieldPath]: constraintsObj }, // constraints expects a flattened object
      allValues,
      { ... { fullMessages }, ...options }
    )
    if (errors) return get(errors, fieldPath)
  }
}

export default createFieldValidator
