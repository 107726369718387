import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { modifyProps, getSet, } from 'lp-hoc'
import { first } from 'lodash'
import { MembershipQuantitySelect, SecondaryMembershipLimitModal, } from '../components'
import { FieldArray, formValueSelector } from 'redux-form'
import { SecondaryMemberDetailForm } from '../forms'
import { differenceWith, getOr } from 'utils'
import { selectors as memberSelectors } from '../../member/reducer'

const propTypes = {
  availableMembers: PropTypes.array,
  membership: PropTypes.object.isRequired,
  includedQuantity: PropTypes.number,
  additionalQuantityPrice: PropTypes.number,
  secondaryMemberCount: PropTypes.number,
  setSecondaryMemberCount: PropTypes.func.isRequired,
  showLimit: PropTypes.func.isRequired,
  hideLimit: PropTypes.func.isRequired,
  secondaryMembershipTypeId: PropTypes.number,
}

const defaultProps = {}

function SecondaryMembershipDetail ({
  availableMembers,
  membership,
  includedQuantity,
  additionalQuantityPrice,
  secondaryMemberCount,
  setSecondaryMemberCount,
  showLimit,
  hideLimit,
  secondaryMembershipTypeId,
}) {
  return (
    <div>
      <MembershipQuantitySelect
        membership={ membership }
        includedQuantity={ includedQuantity }
        additionalQuantityPrice={ additionalQuantityPrice }
        value={ secondaryMemberCount }
        updateQuantity={ (value, offset) => {
          if (value + offset > includedQuantity){
            value = includedQuantity
            showLimit()
            return
          }
          setSecondaryMemberCount(value + offset)
        }}
      />
      <FieldArray
        name="secondaryMembers"
        component={ SecondaryMemberDetailForm }
        props={{
          availableMembers,
          totalQty: secondaryMemberCount,
          secondaryMembershipTypeId: secondaryMembershipTypeId,
        }}
      />
      <SecondaryMembershipLimitModal
        handleHide={ hideLimit }
        limitQuantity={ includedQuantity }
      />
    </div>
  )
}

SecondaryMembershipDetail.propTypes = propTypes
SecondaryMembershipDetail.defaultProps = defaultProps

function mapStateToProps (state) {
  return {
    secondaryMembers: memberSelectors.secondaryMembers(state),
    selectedMembers: formValueSelector('membership-detail')(state, 'secondaryMembers') || [],
  }
}

const mapDispatchToProps = {
  showLimit: SecondaryMembershipLimitModal.show,
  hideLimit: SecondaryMembershipLimitModal.hide,
}

function modify ({
  membership,
  initialValues,
  secondaryMembers,
  selectedMembers
}) {
  if (!membership) return {}
  
  return {
    includedQuantity: (first(membership.secondaryOptions) || {}).maxQuantity,
    additionalQuantityPrice: (first(membership.addOns) || {}).price,
    secondaryMembershipTypeId: (first(membership.secondaryOptions) || {}).centamanId,
    availableMembers: differenceWith(
      secondaryMembers,
      selectedMembers,
      (member, selectedMember) => member.id === selectedMember.id
    ),
    initialValues: {
      secondaryMemberCount: getOr([], 'secondaryMembers', initialValues).length,
    }
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  modifyProps(modify),
  getSet(['secondaryMemberCount', 'secondaryMembers']),
)(SecondaryMembershipDetail)
