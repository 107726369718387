import { validate } from 'lp-utils'
import { compose, omit, pick } from 'lodash/fp'
import identity from 'lodash/identity'
import map from 'lodash/map'

export default function ({
  constraints = {},
  initialValuesFilters = {},
  submitFilters = {},
}) {

  return {

    filterInitialValues: compose(...map(initialValuesFilters, mapFilters)),

    submit: action => compose(action, ...map(submitFilters, mapFilters)),

    validate: validate(constraints),
  }
}

function mapFilters (value, filter) {
  switch(filter) {
    case 'allow':
      return pick(value)
    case 'reject':
      return omit(value)
    default:
      return identity
  }
}