import React from 'react'
import { Link } from 'react-router'
import { compose } from 'redux'
import {
  Field,
  FormSection,
  propTypes as formPropTypes,
} from 'redux-form'
import { ButtonArea, Input, SubmitButton } from 'lp-components'
import { lpForm } from 'lp-form'

function MemberLookupForm ({ handleSubmit, invalid, pristine, submitting }) {
  return (
    <form onSubmit={ handleSubmit }>
      <FormSection name="member">
        <Field name="email" component={ Input } type="email" />
      </FormSection>
      <ButtonArea>
        <SubmitButton className="button-chill" { ...{ invalid, pristine, submitting } }>
          Submit
        </SubmitButton>
        <Link className="login-link" to="/member/login">Back to Log In</Link>
      </ButtonArea>
    </form>
  )
}

MemberLookupForm.propTypes = formPropTypes

export default compose(
  lpForm({
    name: 'member-login',
    constraints: {
      'member.lastName': { presence: true },
      'member.email': { presence: true, email: true, },
    }
  })
)(MemberLookupForm)
