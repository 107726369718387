import React, { useState } from "react";
import PropTypes from "prop-types";
import * as Types from "types";
import exact from "prop-types-exact";
import { Link } from "react-router";
import { Modal, Button } from "lp-components";
import { find, groupBy, sumBy } from "lodash";
import { displayCurrency, sumCurrencyValues, getTimeDisplayText, stripTimezone } from "utils";

const propTypes = {
    lineItem: Types.lineItem.isRequired,
    activity: Types.activity.isRequired,
    handleDeleteLineItem: PropTypes.func,
    editMode: PropTypes.bool
};

const defaultProps = {
    handleDeleteLineItem: null,
    editMode: false,
    showDiff: false
};

function CartLineItem({ lineItem, activity, handleDeleteLineItem, cartToken, editMode, order, showDiff, lineItemIndex }) {
    const shouldDisplayFullDetails = !!handleDeleteLineItem;
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const groupedLineItemTickets = groupBy(lineItem.lineItemTickets, "ticketId");
    const lineItemSubtotal = sumCurrencyValues(
        lineItem.lineItemTickets,
        "subtotal"
    );
    const lineItemTaxes = sumCurrencyValues(lineItem.lineItemTickets, "taxes");
    let editUrl = `/activities/${lineItem.primaryActivityId}?line=${lineItem.id}`
    if (editMode) {
        editUrl += `&cartToken=${cartToken}&orderToken=${order.token}`
    }

    const orderLineItem = editMode && order && find(order.originalOrder.cart.lineItems, (orderLineItem) => lineItem.primaryActivityId == orderLineItem.primaryActivityId && lineItem.bookingId == orderLineItem.bookingId)

    function isModified() {
        // An order is modified if the quantity of the corresponding order.orderTicket has changed or if the activityId of the corresponding order.orderTicket has changed
        if(!orderLineItem) {
            return false
        }
        // if order line item is not the same activity, it means the date, time, and quantity has changed
        if(orderLineItem.primaryActivityId !== activity.id) {
            return true
        }
        // if the order line item is the same activity but the event id is different, it means the date and time has changed
        if(orderLineItem.eventId !== lineItem.eventId) {
            return true
        }
        // if activity found, what could have changed is the quantity
        const sumOfCurrentLineItemTickets = sumBy(lineItem.lineItemTickets, "quantity")
        const sumOfModifiedLineItemTickets = sumBy(orderLineItem.lineItemTickets, "quantity")
        return sumOfModifiedLineItemTickets !== sumOfCurrentLineItemTickets
    }

    function orderLineItemQuantity(lineItem) {
        return sumBy(lineItem.lineItemTickets, "quantity")
    }

    function orderLineItemTotalPaid(lineItem) {
        return sumCurrencyValues(lineItem.lineItemTickets, "total")
    }

    function lineItemEndDateTimeGreaterThanNow(lineItem) {
        const lineItemEndDateTime = stripTimezone(lineItem.endDatetime)
        return new Date(lineItemEndDateTime) > new Date()
    }

    function lineItemDisplayLabel(lineItem, tickets) {
        if(activity.timed && activity.overrideEventTimeWithName) {
            return `${lineItem.eventName} ${getTimeDisplayText(lineItem, activity)}`
        }
        return `${tickets[0].displayName} ${getTimeDisplayText(lineItem, activity)}`
    }

    return (
        <div key={lineItem.id} className="order-line-item relative">
            {
                isModified() && (
                    <div className="modified-badge">
                        Modified
                    </div>
                )
            }
            <div className="product-item">
                {shouldDisplayFullDetails ? (
                    <h3 className="h5">{activity.displayName}</h3>
                ) : (
                    <h2 className="h5">{activity.displayName}</h2>
                )}
                <img src={activity.thumbnail} alt="" className="activity-thumbnail" />
            </div>
            <div className="ticket-info">
                {
                    isModified() && showDiff && (
                        <div className="modified-datetime-wrapper">
                            <p>
                                {orderLineItemQuantity(orderLineItem)} x {orderLineItem.eventTimeDescription}
                            </p>
                            <p className="total-paid">{displayCurrency(orderLineItemTotalPaid(orderLineItem))}</p>
                        </div>
                    )
                }
                <div className="current-tickets-wrapper">
                    <div>
                        {Object.keys(groupedLineItemTickets).map((ticketGroupKey) => {
                            const tickets = groupedLineItemTickets[ticketGroupKey];

                            return (
                                <p key={ticketGroupKey}>
                                    {tickets.length} x {lineItemDisplayLabel(lineItem, tickets)}
                                </p>
                            );
                        })}
                    </div>
                    <p>{displayCurrency(lineItemSubtotal)}</p>
                </div>
            </div>

            {handleDeleteLineItem && (
                <React.Fragment>
                    <div className="shopping-edit">
                        <div>
                            {
                                editMode ? activity.timed ? (
                                    lineItemEndDateTimeGreaterThanNow(lineItem) && (
                                        <Link
                                            to={editUrl}
                                            className="edit"
                                        >
                                            Change reservation
                                        </Link>
                                    )
                                ) : (
                                    <span className="edit"> Please contact <Link to className="edit"> customer service </Link> to make changes </span>
                                )
                                    : (
                                        <div>
                                            <Link
                                                to={editUrl}
                                                className="edit"
                                            >
                                                Edit Details
                                            </Link>
                                            <span aria-hidden="true">|</span>
                                            <button
                                                className="edit"
                                                type="button"
                                                aria-label={`Delete ${activity} tickets from cart`}
                                                onClick={() => setShowDeleteConfirmation(true)}
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                    {lineItemTaxes > 0 && (
                        <div className="shopping-edit">
                            <p>Taxes</p>
                            <p>{displayCurrency(lineItemTaxes)}</p>
                        </div>
                    )}
                </React.Fragment>
            )}
            <hr aria-hidden="true" />
            {showDeleteConfirmation && (
                <Modal onClose={() => setShowDeleteConfirmation(false)}>
                    <div className="remove-cart-item">
                        <h5>Delete Item</h5>
                        <p>{`Are you sure you want to delete ${lineItem.eventName} from your cart?`}</p>
                        <Button
                            type="button"
                            className="button-primary"
                            onClick={() => {
                                setIsDeleting(true);
                                return handleDeleteLineItem().catch(() => {
                                    setIsDeleting(false);
                                });
                            }}
                            aria-label={`Remove ${lineItem.eventName}`}
                            submitting={isDeleting}
                        >
                            Delete Item
                        </Button>
                        <button
                            className="back-link"
                            aria-label={`Cancel removing ${lineItem.eventName} from cart`}
                            onClick={() => setShowDeleteConfirmation(false)}
                            type="button"
                        >
                            Cancel
                        </button>
                    </div>
                </Modal>
            )}
        </div>
    );
}

CartLineItem.propTypes = exact(propTypes);
CartLineItem.defaultProps = defaultProps;

export default React.memo(CartLineItem);
