// eslint-disable-next-line
import React from 'react'
// import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { onMount } from 'lp-hoc'
import * as actions from '../actions'
import * as routerActions from 'react-router-redux'

const propTypes = {}

const defaultProps = {}

// Layout component used for simulating a user logout
function MemberLogout () {
  return null
}

MemberLogout.propTypes = propTypes
MemberLogout.defaultProps = defaultProps

function mapStateToProps () {
  return {}
}

const mapDispatchToProps = {
  logout: actions.clearMemberSession,
  replace: routerActions.replace,
}

function logoutAndRedirect ({ logout, replace }) {
  logout()
  replace('/member/login')
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  onMount(logoutAndRedirect)
)(MemberLogout)
