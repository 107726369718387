import React from "react";
import PropTypes from "prop-types";
import { selectors } from "../reducer";
import { compose } from "recompose";
import { connect } from "react-redux";
import { DetailHeader } from "../components";
import { AddOnsForm } from "../forms";
import * as apiActions from "api-actions";
import { connectParams, onMount, waitFor, modifyProps } from "lp-hoc";
import { LoadingIndicator } from "components";
import BillingDetails from "../../activities/components/billing-details";
import { first, mapFormValuesToArray } from "utils";
import * as routerActions from "react-router-redux";
import { selectors as memberSelectors } from "../../member/reducer";
import * as LS from "local-storage";

const propTypes = {
  membership: PropTypes.object.isRequired,
  mappedMembership: PropTypes.object,
  checkout: PropTypes.object,
  nextStep: PropTypes.func,
  secondaryMembers: PropTypes.array,
  updateMembershipCheckout: PropTypes.func.isRequired,
};

const defaultProps = {
  secondaryMembers: [],
};

function AddOns({
  membership,
  mappedMembership,
  nextStep,
  checkout: { totals, items },
  secondaryMembers,
  updateMembershipCheckout,
}) {
  return (
    <div className="content-choose-tickets">
      <DetailHeader membership={membership} backButton={true} />
      <div className="content">
        <AddOnsForm
          membership={membership}
          options={membership.addOns}
          updateCheckout={updateMembershipCheckout}
          secondaryMembers={secondaryMembers}
          onSubmit={updateMembershipCheckout}
          onSubmitSuccess={() =>
            nextStep(`/memberships/${membership.id}/billing`)
          }
        />
        <div className="ticket-details">
          {totals && (
            <BillingDetails
              activity={mappedMembership}
              orderInfo={totals}
              tickets={items}
              isTimedActivity={false}
            />
          )}
        </div>
      </div>
    </div>
  );
}

AddOns.propTypes = propTypes;
AddOns.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    membership: selectors.selectedMembership(state),
    checkout: selectors.checkout(state),
    secondaryMembers: memberSelectors.secondaryMembers(state),
  };
}

const mapDispatchToProps = {
  fetchMembership: apiActions.fetchMembershipDetails,
  nextStep: routerActions.push,
  redirect: routerActions.replace,
  updateCheckout: apiActions.updateMembershipCheckout,
};

function onComponentDidMount({
  fetchMembership,
  id,
  membership,
  redirect,
  updateCheckout,
}) {
  if (membership && membership.id === id) return;
  return fetchMembership(id)
    .then(() => {
      updateCheckout({
        membershipId: id,
        ...LS.getCheckout(),
      });
    })
    .catch((e) => {
      if (e && e.status === 404) redirect("/memberships/not-found");
    });
}

function modify({ updateCheckout, membership, checkout, id }) {
  if (!membership) return {};
  return {
    mappedMembership: {
      ...membership,
      thumbnail: membership.membershipImage,
    },
    membershipTypeId: first(membership.primaryOptions).centamanId,
    checkout: {
      ...checkout,
      membershipId: id,
    },
    updateMembershipCheckout: (params) => {
      return updateCheckout({
        ...params,
        addOnMembers: mapFormValuesToArray(params.addOnMembers),
      });
    },
  };
}

export default compose(
  connectParams("id"),
  connect(mapStateToProps, mapDispatchToProps),
  onMount(onComponentDidMount),
  modifyProps(modify),
  waitFor(["membership", "checkout"], LoadingIndicator)
)(AddOns);
