import React  from "react";
import PropTypes from "prop-types"
import * as Types from "types";
import { compose } from "recompose";
import { lpForm } from "lp-form";
import { Field, FormSection } from "redux-form";
import { noop } from 'lodash'
import moment from "moment"
import { MomentDateInput, UdfField } from "components"
import { SubmitButton, Input } from 'lp-components'

const propTypes = {
  activity: Types.activity.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  heading: PropTypes.string,
  onCancel: PropTypes.func
};

const defaultProps = {
  heading: 'Attendee Details',
  onCancel: noop
};

function AttendeeDetailsForm({
  activity,
  handleSubmit,
  heading,
  onCancel
}) {
  return (
    <form onSubmit={handleSubmit}>
        <div className="step" aria-label="Enter attendee details">
            <h3 className="h4">{heading}</h3>
            <FormSection name="attendeeDetails">
              <Field
                label="First Name"
                component={Input}
                name="firstName"
              />
              <Field
                label="Last Name"
                component={Input}
                name="lastName"
              />
              {activity.collectAttendeeDob && (
                <Field
                  label="Birthdate"
                  name="dateOfBirth"
                  placeholderText="Select"
                  component={MomentDateInput}
                  maxDate={moment()}
                  dateFormat="DD MMMM, YYYY"
                  autoComplete="off"
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                />
              )}
            </FormSection>
            <FormSection name="attendeeUdfs">
                {activity.attendeeUdfs.map((udf) => {
                  return <UdfField key={udf.id} udf={udf}/>
                })}
            </FormSection>
            <div className="button-group-large">
              <SubmitButton>Save Details</SubmitButton>
              <button className="link-primary" type="button" onClick={() => onCancel()} aria-label="Back to select tickets">
                Cancel
              </button>
            </div>
        </div>
    </form>
  );
}

AttendeeDetailsForm.propTypes = propTypes
AttendeeDetailsForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: "attendee-udf-form",
    constraints: {
      'attendeeDetails.firstName': { presence: true },
      'attendeeDetails.lastName': { presence: true },
      'attendeeDetails.dateOfBirth': { presence: true }
    },
    submitOnChange: false,
  })
)(AttendeeDetailsForm);
