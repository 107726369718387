import React from 'react'
import Content from './content'

function LoadingIndicator () {
  return (
    <Content>
      <div id="spinner"></div>
    </Content>
  )
}

export default LoadingIndicator
