import React from 'react'
import { Route } from 'react-router'
import * as Views from './views'
import Layout from './Layout'

const Routes = (
  <Route path="member" component={ Layout }>
    <Route path="login" component={ Views.MemberLogin }/>
    <Route path="lookup" component={ Views.MemberLookup }/>
    <Route path="logout" component={ Views.MemberLogout }/>
  </Route>
)

export default Routes
