import React from "react";
import exact from "prop-types-exact";
import PropTypes from 'prop-types'

const propTypes = {
  fillColor: PropTypes.string
};

const defaultProps = {
  fillColor: '#FFFFFF'
};

function CartIcon({ fillColor }) {
  return (
    <svg width="532px" viewBox="0 0 532 502" version="1.1" className="cart-grey-fill">
      <title>Group</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill={fillColor}
        fillRule="evenodd"
      >
        <g
          id="Group"
          transform="translate(0.001000, 0.681000)"
          fill={fillColor}
          fillRule="nonzero"
        >
          <path
            d="M478.239,82.32 L137.199,82.32 L126.558,40.879 C120.3978,16.801 99.117,-2.84217094e-14 74.48,-2.84217094e-14 L17.359,-2.84217094e-14 C7.8395,-2.84217094e-14 0,7.8398 0,17.359 C0,26.8785 7.8398,34.718 17.359,34.718 L74.48,34.718 C82.8784,34.718 90.16,40.3196 92.402,48.718 L160.722,320.878 C166.8822,344.956 188.163,361.757 212.8,361.757 L436.24,361.757 C460.881,361.757 482.72,344.956 488.318,320.878 L530.318,148.398 C534.236,132.718 530.87659,115.921 520.236,103.039 C510.161,90.16 494.477,82.32 478.239,82.32 Z M496.161,140.559 L454.161,313.039 C451.9227,321.4374 444.6415,327.039 436.239,327.039 L212.799,327.039 C204.4006,327.039 197.119,321.4374 194.877,313.039 L146.158,117.599 L478.238,117.599 C483.8396,117.599 489.437,120.3998 492.797,124.8802 C496.1603,129.3607 497.84,134.9622 496.1603,140.5602 L496.161,140.559 Z"
            id="Shape"
          ></path>
          <path
            d="M224.559,384.159 C192.637,384.159 166.321,410.479 166.321,442.397 C166.321,474.319 192.641,500.635 224.559,500.635 C256.481,500.638906 282.801,474.319 282.801,442.397 C282.801,410.479 256.481,384.159 224.559,384.159 L224.559,384.159 Z M224.559,465.358 C211.68,465.358 201.598,455.28 201.598,442.397 C201.598,429.518 211.676,419.436 224.559,419.436 C237.438,419.436 247.52,429.514 247.52,442.397 C247.52,454.717 236.879,465.358 224.559,465.358 Z"
            id="Shape"
          ></path>
          <path
            d="M417.199,384.159 C385.277,384.159 358.961,410.479 358.961,442.397 C358.961,474.319 385.281,500.635 417.199,500.635 C449.121,500.635 475.437,474.315 475.437,442.397 C474.87841,410.479 449.121,384.159 417.199,384.159 L417.199,384.159 Z M417.199,465.358 C404.32,465.358 394.238,455.28 394.238,442.397 C394.238,429.518 404.316,419.436 417.199,419.436 C430.078,419.436 440.16,429.514 440.16,442.397 C440.16,454.717 429.519,465.358 417.199,465.358 L417.199,465.358 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
}

CartIcon.propTypes = exact(propTypes);
CartIcon.defaultProps = defaultProps;

export default React.memo(CartIcon);
