import React from "react";
import PropTypes from "prop-types";
import { selectors } from "../reducer";
import { compose } from "recompose";
import { connect } from "react-redux";
import { DetailHeader } from "../components";
import { PrimaryMembershipDetailsForm } from "../forms";
import * as apiActions from "api-actions";
import { connectParams, onMount, waitFor, modifyProps, onUpdate } from "lp-hoc";
import { LoadingIndicator } from "components";
import SecondaryMembershipDetail from "./SecondaryMembershipDetail";
import BillingDetails from "../../activities/components/billing-details";
import { first } from "lodash";
import * as routerActions from "react-router-redux";
import * as LS from "local-storage";
import { get, pick, set, setIf } from "utils";

const propTypes = {
  membership: PropTypes.object.isRequired,
  hasAdditionalMembers: PropTypes.bool,
  mappedMembership: PropTypes.object,
  checkout: PropTypes.object,
  nextStep: PropTypes.func,
  updateCheckout: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  updateMembershipCheckout: PropTypes.func.isRequired,
};

const defaultProps = {};

function MembershipsDetail({
  membership,
  hasAdditionalMembers,
  mappedMembership,
  nextStep,
  updateCheckout,
  initialValues,
  checkout,
  checkout: { totals, items },
  updateMembershipCheckout,
}) {
  return (
    <div className="content-choose-tickets">
      <DetailHeader membership={membership} />
      <div className="content">
        <PrimaryMembershipDetailsForm
          options={membership.primaryOptions}
          initialValues={initialValues}
          onSubmit={(params) => {
            updateCheckout({
              ...checkout,
              ...params,
            });
          }}
          onSubmitSuccess={() =>
            nextStep(
              `/memberships/${membership.id}/${
                membership.addOns.length > 0 ? "add-ons" : "billing"
              }`
            )
          }
          updateCheckout={updateMembershipCheckout}
        >
          {hasAdditionalMembers && (
            <SecondaryMembershipDetail
              membership={membership}
              initialValues={initialValues}
            />
          )}
        </PrimaryMembershipDetailsForm>
        <div className="ticket-details">
          {totals && (
            <BillingDetails
              activity={mappedMembership}
              orderInfo={totals}
              tickets={items}
              isTimedActivity={false}
            />
          )}
        </div>
      </div>
    </div>
  );
}

MembershipsDetail.propTypes = propTypes;
MembershipsDetail.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    membership: selectors.selectedMembership(state),
    checkout: selectors.checkout(state),
  };
}

const mapDispatchToProps = {
  fetchMembership: apiActions.fetchMembershipDetails,
  redirect: routerActions.replace,
  updateCheckout: apiActions.updateMembershipCheckout,
  nextStep: routerActions.push,
};

function onComponentDidMount({ fetchMembership, membershipId, redirect }) {
  fetchMembership(membershipId).catch((e) => {
    if (e && e.status === 404) redirect("/memberships/not-found");
  });
}

function modify({ updateCheckout, membership, checkout, membershipId }) {
  if (!membership) return {};
  return {
    hasAdditionalMembers: membership.secondaryOptions.length > 0,
    mappedMembership: {
      ...membership,
      thumbnail: membership.membershipImage,
    },
    checkout: {
      ...checkout,
      membershipId,
    },
    membershipTypeId: first(membership.primaryOptions).centamanId,
    initialValues: {
      ...pick(["primaryMember", "primaryMemberId"], LS.getMembershipDetails()),
      ...LS.getCheckout(),
    },
    updateMembershipCheckout: (args) => {
      let params = {
        membershipId,
        ...checkout,
        ...args,
      };
      updateCheckout(params);
    },
  };
}

function onComponentDidUpdate({
  updateCheckout,
  checkout,
  initialValues,
  membershipTypeId,
  membershipId,
}) {
  // Add the first primary membership type id if one doesn't already exist
  const params = setIf(
    !get("primaryMember.membershipTypeId", initialValues),
    "primaryMember.membershipTypeId",
    membershipTypeId
  )({
    ...checkout,
    ...initialValues,
  });

  // Reset the cart with the default selection if the selected membership id changes
  if (params.membershipId !== membershipId) {
    const updatedSelection = compose(
      set("membershipId", membershipId),
      set("primaryMember.membershipTypeId", membershipTypeId),
      set("secondaryMembers", []),
      set("addOnMembers", [])
    )(params);

    return updateCheckout(updatedSelection);
  }

  if (!checkout.totals) updateCheckout(params);
}

export default compose(
  connectParams(({ id }) => ({ membershipId: id })),
  connect(mapStateToProps, mapDispatchToProps),
  onMount(onComponentDidMount),
  modifyProps(modify),
  onUpdate(onComponentDidUpdate),
  waitFor("membership", LoadingIndicator)
)(MembershipsDetail);
