import React from 'react'
import Header from '../main/header/header'
import { Link } from 'react-router'

function NotFound () {
  return (
    <div>
      <Header />
      <main className="content page-error">
        <h1>Page Not Found</h1>
        <p>
          Sorry about that, but looks like the page you're looking for either
          doesn't exist or was moved.
        </p>
        <Link className="button-chill" to="/activities">
          Back to Ticketing
        </Link>
      </main>
    </div>
  );
}

export default NotFound
