import { handleActions } from 'redux-actions'
import { setFromRequest } from 'lp-redux-api'
import { selectorForSlice } from 'lp-utils'
import { REQ_NAV_ITEMS } from 'api-actions'

const reducerKey = 'header'
const slice = 'root.header'

const initialState = {
  navItems: {},
}

const reducer = handleActions({
  ...setFromRequest(REQ_NAV_ITEMS, 'navItems'),
}, initialState)

const select = selectorForSlice(slice)

const selectors = {
  navItems: select('navItems.success.items', []),
}

export { reducer, selectors, reducerKey }
