import React from 'react'
import exact from 'prop-types-exact'
import { Spinner } from 'lp-components'
import Content from './content'

const propTypes = {}

const defaultProps = {}

function LoadingState () {
    return (
        <Content>
            <Spinner/>
        </Content>
    )
}

LoadingState.propTypes = exact(propTypes)
LoadingState.defaultProps = defaultProps

export default React.memo(LoadingState)