const usStates = [
  {
    key: "Alabama",
    value: "AL"
  },
  {
    key: "Alaska",
    value: "AK"
  },
  {
    key: "American Samoa",
    value: "AS"
  },
  {
    key: "Arizona",
    value: "AZ"
  },
  {
    key: "Arkansas",
    value: "AR"
  },
  {
    key: "California",
    value: "CA"
  },
  {
    key: "Colorado",
    value: "CO"
  },
  {
    key: "Connecticut",
    value: "CT"
  },
  {
    key: "Delaware",
    value: "DE"
  },
  {
    key: "District Of Columbia",
    value: "DC"
  },
  {
    key: "Federated States Of Micronesia",
    value: "FM"
  },
  {
    key: "Florida",
    value: "FL"
  },
  {
    key: "Georgia",
    value: "GA"
  },
  {
    key: "Guam",
    value: "GU"
  },
  {
    key: "Hawaii",
    value: "HI"
  },
  {
    key: "Idaho",
    value: "ID"
  },
  {
    key: "Illinois",
    value: "IL"
  },
  {
    key: "Indiana",
    value: "IN"
  },
  {
    key: "Iowa",
    value: "IA"
  },
  {
    key: "Kansas",
    value: "KS"
  },
  {
    key: "Kentucky",
    value: "KY"
  },
  {
    key: "Louisiana",
    value: "LA"
  },
  {
    key: "Maine",
    value: "ME"
  },
  {
    key: "Marshall Islands",
    value: "MH"
  },
  {
    key: "Maryland",
    value: "MD"
  },
  {
    key: "Massachusetts",
    value: "MA"
  },
  {
    key: "Michigan",
    value: "MI"
  },
  {
    key: "Minnesota",
    value: "MN"
  },
  {
    key: "Mississippi",
    value: "MS"
  },
  {
    key: "Missouri",
    value: "MO"
  },
  {
    key: "Montana",
    value: "MT"
  },
  {
    key: "Nebraska",
    value: "NE"
  },
  {
    key: "Nevada",
    value: "NV"
  },
  {
    key: "New Hampshire",
    value: "NH"
  },
  {
    key: "New Jersey",
    value: "NJ"
  },
  {
    key: "New Mexico",
    value: "NM"
  },
  {
    key: "New York",
    value: "NY"
  },
  {
    key: "North Carolina",
    value: "NC"
  },
  {
    key: "North Dakota",
    value: "ND"
  },
  {
    key: "Northern Mariana Islands",
    value: "MP"
  },
  {
    key: "Ohio",
    value: "OH"
  },
  {
    key: "Oklahoma",
    value: "OK"
  },
  {
    key: "Oregon",
    value: "OR"
  },
  {
    key: "Palau",
    value: "PW"
  },
  {
    key: "Pennsylvania",
    value: "PA"
  },
  {
    key: "Puerto Rico",
    value: "PR"
  },
  {
    key: "Rhode Island",
    value: "RI"
  },
  {
    key: "South Carolina",
    value: "SC"
  },
  {
    key: "South Dakota",
    value: "SD"
  },
  {
    key: "Tennessee",
    value: "TN"
  },
  {
    key: "Texas",
    value: "TX"
  },
  {
    key: "Utah",
    value: "UT"
  },
  {
    key: "Vermont",
    value: "VT"
  },
  {
    key: "Virgin Islands",
    value: "VI"
  },
  {
    key: "Virginia",
    value: "VA"
  },
  {
    key: "Washington",
    value: "WA"
  },
  {
    key: "West Virginia",
    value: "WV"
  },
  {
    key: "Wisconsin",
    value: "WI"
  },
  {
    key: "Wyoming",
    value: "WY"
  }
]

export const auStates = [
  {
    key: "Australian Capital Territory",
    value: "AUSTRALIAN CAPITAL TERRITORY"
  },
  { key: "New South Wales", value: "NEW SOUTH WALES" },
  { key: "Northern Territory", value: "NORTHERN TERRITORY" },
  { key: "Queensland", value: "QUEENSLAND" },
  { key: "South Australia", value: "SOUTH AUSTRALIA" },
  { key: "Tasmania", value: "TASMANIA" },
  { key: "Victoria", value: "VICTORIA" },
  { key: "Western Australia", value: "WESTERN AUSTRALIA" }
]

export const countries = [
  { key: "American Samoa", value: "AMERICAN SAMOA", isoCode: "AS" },
  { key: "Andorra", value: "ANDORRA", isoCode: "AD" },
  { key: "Angola", value: "ANGOLA", isoCode: "AO" },
  { key: "Anguilla", value: "ANGUILLA", isoCode: "AI" },
  { key: "Antarctica", value: "ANTARCTICA", isoCode: "AQ" },
  { key: "Antigua and Barbuda", value: "ANTIGUA AND BARBUDA", isoCode: "AG" },
  { key: "Argentina", value: "ARGENTINA", isoCode: "AR" },
  { key: "Armenia", value: "ARMENIA", isoCode: "AM" },
  { key: "Aruba", value: "ARUBA", isoCode: "AW" },
  { key: "Australia", value: "AUSTRALIA", isoCode: "AU", states: auStates },
  { key: "Austria", value: "AUSTRIA", isoCode: "AT" },
  { key: "Azerbaijan", value: "AZERBAIJAN", isoCode: "AZ" },
  { key: "Bahamas", value: "BAHAMAS", isoCode: "BS" },
  { key: "Bahrain", value: "BAHRAIN", isoCode: "BH" },
  { key: "Bangladesh", value: "BANGLADESH", isoCode: "BD" },
  { key: "Barbados", value: "BARBADOS", isoCode: "BB" },
  { key: "Belarus", value: "BELARUS", isoCode: "BY" },
  { key: "Belgium", value: "BELGIUM", isoCode: "BE" },
  { key: "Belize", value: "BELIZE", isoCode: "BZ" },
  { key: "Benin", value: "BENIN", isoCode: "BJ" },
  { key: "Bermuda", value: "BERMUDA", isoCode: "BM" },
  { key: "Bhutan", value: "BHUTAN", isoCode: "BT" },
  { key: "Bolivia", value: "BOLIVIA", isoCode: "BO" },
  {
    key: "Bosnia and Herzegovina",
    value: "BOSNIA AND HERZEGOVINA",
    isoCode: "BA"
  },
  { key: "Botswana", value: "BOTSWANA", isoCode: "BW" },
  { key: "Bouvet Island", value: "BOUVET ISLAND", isoCode: "BV" },
  { key: "Brazil", value: "BRAZIL", isoCode: "BR" },
  {
    key: "British Indian Ocean Territory",
    value: "BRITISH INDIAN OCEAN TERRITORY",
    isoCode: "IO"
  },
  { key: "Brunei Darussalam", value: "BRUNEI DARUSSALAM", isoCode: "BN" },
  { key: "Bulgaria", value: "BULGARIA", isoCode: "BG" },
  { key: "Burkina Faso", value: "BURKINA FASO", isoCode: "BF" },
  { key: "Burundi", value: "BURUNDI", isoCode: "BI" },
  { key: "Cambodia", value: "CAMBODIA", isoCode: "KH" },
  { key: "Cameroon", value: "CAMEROON", isoCode: "CM" },
  { key: "Canada", value: "CANADA", isoCode: "CA" },
  { key: "Cape Verde", value: "CAPE VERDE", isoCode: "CV" },
  { key: "Cayman Islands", value: "CAYMAN ISLANDS", isoCode: "KY" },
  {
    key: "Central African Republic",
    value: "CENTRAL AFRICAN REPUBLIC",
    isoCode: "CF"
  },
  { key: "Chad", value: "CHAD", isoCode: "TD" },
  { key: "Chile", value: "CHILE", isoCode: "CL" },
  { key: "China", value: "CHINA", isoCode: "CN" },
  { key: "Christmas Island", value: "CHRISTMAS ISLAND", isoCode: "CX" },
  {
    key: "Cocos (Keeling) Islands",
    value: "COCOS (KEELING) ISLANDS",
    isoCode: "CC"
  },
  { key: "Colombia", value: "COLOMBIA", isoCode: "CO" },
  { key: "Comoros", value: "COMOROS", isoCode: "KM" },
  { key: "Congo", value: "CONGO", isoCode: "CG" },
  {
    key: "Congo, The Democratic Republic of the",
    value: "CONGO, THE DEMOCRATIC REPUBLIC OF THE",
    isoCode: "CD"
  },
  { key: "Cook Islands", value: "COOK ISLANDS", isoCode: "CK" },
  { key: "Costa Rica", value: "COSTA RICA", isoCode: "CR" },
  { key: "Cote D'Ivoire", value: "COTE D'IVOIRE", isoCode: "CI" },
  { key: "Croatia", value: "CROATIA", isoCode: "HR" },
  { key: "Cuba", value: "CUBA", isoCode: "CU" },
  { key: "Cyprus", value: "CYPRUS", isoCode: "CY" },
  { key: "Czech Republic", value: "CZECH REPUBLIC", isoCode: "CZ" },
  { key: "Denmark", value: "DENMARK", isoCode: "DK" },
  { key: "Djibouti", value: "DJIBOUTI", isoCode: "DJ" },
  { key: "Dominica", value: "DOMINICA", isoCode: "DM" },
  { key: "Dominican Republic", value: "DOMINICAN REPUBLIC", isoCode: "DO" },
  { key: "Ecuador", value: "ECUADOR", isoCode: "EC" },
  { key: "Egypt", value: "EGYPT", isoCode: "EG" },
  { key: "El Salvador", value: "EL SALVADOR", isoCode: "SV" },
  { key: "Equatorial Guinea", value: "EQUATORIAL GUINEA", isoCode: "GQ" },
  { key: "Eritrea", value: "ERITREA", isoCode: "ER" },
  { key: "Estonia", value: "ESTONIA", isoCode: "EE" },
  { key: "Ethiopia", value: "ETHIOPIA", isoCode: "ET" },
  {
    key: "Falkland Islands (Malvinas)",
    value: "FALKLAND ISLANDS (MALVINAS)",
    isoCode: "FK"
  },
  { key: "Faroe Islands", value: "FAROE ISLANDS", isoCode: "FO" },
  { key: "Fiji", value: "FIJI", isoCode: "FJ" },
  { key: "Finland", value: "FINLAND", isoCode: "FI" },
  { key: "France", value: "FRANCE", isoCode: "FR" },
  { key: "French Guiana", value: "FRENCH GUIANA", isoCode: "GF" },
  { key: "French Polynesia", value: "FRENCH POLYNESIA", isoCode: "PF" },
  {
    key: "French Southern Territories",
    value: "FRENCH SOUTHERN TERRITORIES",
    isoCode: "TF"
  },
  { key: "Gabon", value: "GABON", isoCode: "GA" },
  { key: "Gambia", value: "GAMBIA", isoCode: "GM" },
  { key: "Georgia", value: "GEORGIA", isoCode: "GE" },
  { key: "Germany", value: "GERMANY", isoCode: "DE" },
  { key: "Ghana", value: "GHANA", isoCode: "GH" },
  { key: "Gibraltar", value: "GIBRALTAR", isoCode: "GI" },
  { key: "Greece", value: "GREECE", isoCode: "GR" },
  { key: "Greenland", value: "GREENLAND", isoCode: "GL" },
  { key: "Grenada", value: "GRENADA", isoCode: "GD" },
  { key: "Guadeloupe", value: "GUADELOUPE", isoCode: "GP" },
  { key: "Guam", value: "GUAM", isoCode: "GU" },
  { key: "Guatemala", value: "GUATEMALA", isoCode: "GT" },
  { key: "Guernsey", value: "GUERNSEY", isoCode: "GG" },
  { key: "Guinea", value: "GUINEA", isoCode: "GN" },
  { key: "Guinea-Bissau", value: "GUINEA-BISSAU", isoCode: "GW" },
  { key: "Guyana", value: "GUYANA", isoCode: "GY" },
  { key: "Haiti", value: "HAITI", isoCode: "HT" },
  {
    key: "Heard Island and Mcdonald Islands",
    value: "HEARD ISLAND AND MCDONALD ISLANDS",
    isoCode: "HM"
  },
  {
    key: "Holy See (Vatican City State)",
    value: "HOLY SEE (VATICAN CITY STATE)",
    isoCode: "VA"
  },
  { key: "Honduras", value: "HONDURAS", isoCode: "HN" },
  { key: "Hong Kong", value: "HONG KONG", isoCode: "HK" },
  { key: "Hungary", value: "HUNGARY", isoCode: "HU" },
  { key: "Iceland", value: "ICELAND", isoCode: "IS" },
  { key: "India", value: "INDIA", isoCode: "IN" },
  { key: "Indonesia", value: "INDONESIA", isoCode: "ID" },
  {
    key: "Iran, Islamic Republic Of",
    value: "IRAN, ISLAMIC REPUBLIC OF",
    isoCode: "IR"
  },
  { key: "Iraq", value: "IRAQ", isoCode: "IQ" },
  { key: "Ireland", value: "IRELAND", isoCode: "IE" },
  { key: "Isle of Man", value: "ISLE OF MAN", isoCode: "IM" },
  { key: "Israel", value: "ISRAEL", isoCode: "IL" },
  { key: "Italy", value: "ITALY", isoCode: "IT" },
  { key: "Jamaica", value: "JAMAICA", isoCode: "JM" },
  { key: "Japan", value: "JAPAN", isoCode: "JP" },
  { key: "Jersey", value: "JERSEY", isoCode: "JE" },
  { key: "Jordan", value: "JORDAN", isoCode: "JO" },
  { key: "Kazakhstan", value: "KAZAKHSTAN", isoCode: "KZ" },
  { key: "Kenya", value: "KENYA", isoCode: "KE" },
  { key: "Kiribati", value: "KIRIBATI", isoCode: "KI" },
  {
    key: "Korea, Democratic People'S Republic of",
    value: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
    isoCode: "KP"
  },
  { key: "Korea, Republic of", value: "KOREA, REPUBLIC OF", isoCode: "KR" },
  { key: "Kuwait", value: "KUWAIT", isoCode: "KW" },
  { key: "Kyrgyzstan", value: "KYRGYZSTAN", isoCode: "KG" },
  {
    key: "Lao People'S Democratic Republic",
    value: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
    isoCode: "LA"
  },
  { key: "Latvia", value: "LATVIA", isoCode: "LV" },
  { key: "Lebanon", value: "LEBANON", isoCode: "LB" },
  { key: "Lesotho", value: "LESOTHO", isoCode: "LS" },
  { key: "Liberia", value: "LIBERIA", isoCode: "LR" },
  {
    key: "Libyan Arab Jamahiriya",
    value: "LIBYAN ARAB JAMAHIRIYA",
    isoCode: "LY"
  },
  { key: "Liechtenstein", value: "LIECHTENSTEIN", isoCode: "LI" },
  { key: "Lithuania", value: "LITHUANIA", isoCode: "LT" },
  { key: "Luxembourg", value: "LUXEMBOURG", isoCode: "LU" },
  { key: "Macao", value: "MACAO", isoCode: "MO" },
  {
    key: "Macedonia, The Former Yugoslav Republic of",
    value: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF",
    isoCode: "MK"
  },
  { key: "Madagascar", value: "MADAGASCAR", isoCode: "MG" },
  { key: "Malawi", value: "MALAWI", isoCode: "MW" },
  { key: "Malaysia", value: "MALAYSIA", isoCode: "MY" },
  { key: "Maldives", value: "MALDIVES", isoCode: "MV" },
  { key: "Mali", value: "MALI", isoCode: "ML" },
  { key: "Malta", value: "MALTA", isoCode: "MT" },
  { key: "Marshall Islands", value: "MARSHALL ISLANDS", isoCode: "MH" },
  { key: "Martinique", value: "MARTINIQUE", isoCode: "MQ" },
  { key: "Mauritania", value: "MAURITANIA", isoCode: "MR" },
  { key: "Mauritius", value: "MAURITIUS", isoCode: "MU" },
  { key: "Mayotte", value: "MAYOTTE", isoCode: "YT" },
  { key: "Mexico", value: "MEXICO", isoCode: "MX" },
  {
    key: "Micronesia, Federated States of",
    value: "MICRONESIA, FEDERATED STATES OF",
    isoCode: "FM"
  },
  { key: "Moldova, Republic of", value: "MOLDOVA, REPUBLIC OF", isoCode: "MD" },
  { key: "Monaco", value: "MONACO", isoCode: "MC" },
  { key: "Mongolia", value: "MONGOLIA", isoCode: "MN" },
  { key: "Montserrat", value: "MONTSERRAT", isoCode: "MS" },
  { key: "Morocco", value: "MOROCCO", isoCode: "MA" },
  { key: "Mozambique", value: "MOZAMBIQUE", isoCode: "MZ" },
  { key: "Myanmar", value: "MYANMAR", isoCode: "MM" },
  { key: "Namibia", value: "NAMIBIA", isoCode: "NA" },
  { key: "Nauru", value: "NAURU", isoCode: "NR" },
  { key: "Nepal", value: "NEPAL", isoCode: "NP" },
  { key: "Netherlands", value: "NETHERLANDS", isoCode: "NL" },
  { key: "Netherlands Antilles", value: "NETHERLANDS ANTILLES", isoCode: "AN" },
  { key: "New Caledonia", value: "NEW CALEDONIA", isoCode: "NC" },
  { key: "New Zealand", value: "NEW ZEALAND", isoCode: "NZ" },
  { key: "Nicaragua", value: "NICARAGUA", isoCode: "NI" },
  { key: "Niger", value: "NIGER", isoCode: "NE" },
  { key: "Nigeria", value: "NIGERIA", isoCode: "NG" },
  { key: "Niue", value: "NIUE", isoCode: "NU" },
  { key: "Norfolk Island", value: "NORFOLK ISLAND", isoCode: "NF" },
  {
    key: "Northern Mariana Islands",
    value: "NORTHERN MARIANA ISLANDS",
    isoCode: "MP"
  },
  { key: "Norway", value: "NORWAY", isoCode: "NO" },
  { key: "Oman", value: "OMAN", isoCode: "OM" },
  { key: "Pakistan", value: "PAKISTAN", isoCode: "PK" },
  { key: "Palau", value: "PALAU", isoCode: "PW" },
  {
    key: "Palestinian Territory, Occupied",
    value: "PALESTINIAN TERRITORY, OCCUPIED",
    isoCode: "PS"
  },
  { key: "Panama", value: "PANAMA", isoCode: "PA" },
  { key: "Papua New Guinea", value: "PAPUA NEW GUINEA", isoCode: "PG" },
  { key: "Paraguay", value: "PARAGUAY", isoCode: "PY" },
  { key: "Peru", value: "PERU", isoCode: "PE" },
  { key: "Philippines", value: "PHILIPPINES", isoCode: "PH" },
  { key: "Pitcairn", value: "PITCAIRN", isoCode: "PN" },
  { key: "Poland", value: "POLAND", isoCode: "PL" },
  { key: "Portugal", value: "PORTUGAL", isoCode: "PT" },
  { key: "Puerto Rico", value: "PUERTO RICO", isoCode: "PR" },
  { key: "Qatar", value: "QATAR", isoCode: "QA" },
  { key: "Reunion", value: "REUNION", isoCode: "RE" },
  { key: "Romania", value: "ROMANIA", isoCode: "RO" },
  { key: "Russian Federation", value: "RUSSIAN FEDERATION", isoCode: "RU" },
  { key: "RWANDA", value: "RWANDA", isoCode: "RW" },
  { key: "Saint Helena", value: "SAINT HELENA", isoCode: "SH" },
  {
    key: "Saint Kitts and Nevis",
    value: "SAINT KITTS AND NEVIS",
    isoCode: "KN"
  },
  { key: "Saint Lucia", value: "SAINT LUCIA", isoCode: "LC" },
  {
    key: "Saint Pierre and Miquelon",
    value: "SAINT PIERRE AND MIQUELON",
    isoCode: "PM"
  },
  {
    key: "Saint Vincent and the Grenadines",
    value: "SAINT VINCENT AND THE GRENADINES",
    isoCode: "VC"
  },
  { key: "Samoa", value: "SAMOA", isoCode: "WS" },
  { key: "San Marino", value: "SAN MARINO", isoCode: "SM" },
  {
    key: "Sao Tome and Principe",
    value: "SAO TOME AND PRINCIPE",
    isoCode: "ST"
  },
  { key: "Saudi Arabia", value: "SAUDI ARABIA", isoCode: "SA" },
  { key: "Senegal", value: "SENEGAL", isoCode: "SN" },
  {
    key: "Serbia and Montenegro",
    value: "SERBIA AND MONTENEGRO",
    isoCode: "CS"
  },
  { key: "Seychelles", value: "SEYCHELLES", isoCode: "SC" },
  { key: "Sierra Leone", value: "SIERRA LEONE", isoCode: "SL" },
  { key: "Singapore", value: "SINGAPORE", isoCode: "SG" },
  { key: "Slovakia", value: "SLOVAKIA", isoCode: "SK" },
  { key: "Slovenia", value: "SLOVENIA", isoCode: "SI" },
  { key: "Solomon Islands", value: "SOLOMON ISLANDS", isoCode: "SB" },
  { key: "Somalia", value: "SOMALIA", isoCode: "SO" },
  { key: "South Africa", value: "SOUTH AFRICA", isoCode: "ZA" },
  {
    key: "South Georgia and the South Sandwich Islands",
    value: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
    isoCode: "GS"
  },
  { key: "Spain", value: "SPAIN", isoCode: "ES" },
  { key: "Sri Lanka", value: "SRI LANKA", isoCode: "LK" },
  { key: "Sudan", value: "SUDAN", isoCode: "SD" },
  { key: "Surikey", value: "SURIKEY", isoCode: "SR" },
  {
    key: "Svalbard and Jan Mayen",
    value: "SVALBARD AND JAN MAYEN",
    isoCode: "SJ"
  },
  { key: "Swaziland", value: "SWAZILAND", isoCode: "SZ" },
  { key: "Sweden", value: "SWEDEN", isoCode: "SE" },
  { key: "Switzerland", value: "SWITZERLAND", isoCode: "CH" },
  { key: "Syrian Arab Republic", value: "SYRIAN ARAB REPUBLIC", isoCode: "SY" },
  {
    key: "Taiwan, Province of China",
    value: "TAIWAN, PROVINCE OF CHINA",
    isoCode: "TW"
  },
  { key: "Tajikistan", value: "TAJIKISTAN", isoCode: "TJ" },
  {
    key: "Tanzania, United Republic of",
    value: "TANZANIA, UNITED REPUBLIC OF",
    isoCode: "TZ"
  },
  { key: "Thailand", value: "THAILAND", isoCode: "TH" },
  { key: "Timor-Leste", value: "TIMOR-LESTE", isoCode: "TL" },
  { key: "Togo", value: "TOGO", isoCode: "TG" },
  { key: "Tokelau", value: "TOKELAU", isoCode: "TK" },
  { key: "Tonga", value: "TONGA", isoCode: "TO" },
  { key: "Trinidad and Tobago", value: "TRINIDAD AND TOBAGO", isoCode: "TT" },
  { key: "Tunisia", value: "TUNISIA", isoCode: "TN" },
  { key: "Turkey", value: "TURKEY", isoCode: "TR" },
  { key: "Turkmenistan", value: "TURKMENISTAN", isoCode: "TM" },
  {
    key: "Turks and Caicos Islands",
    value: "TURKS AND CAICOS ISLANDS",
    isoCode: "TC"
  },
  { key: "Tuvalu", value: "TUVALU", isoCode: "TV" },
  { key: "Uganda", value: "UGANDA", isoCode: "UG" },
  { key: "Ukraine", value: "UKRAINE", isoCode: "UA" },
  { key: "United Arab Emirates", value: "UNITED ARAB EMIRATES", isoCode: "AE" },
  { key: "United Kingdom", value: "UNITED KINGDOM", isoCode: "GB" },
  { key: "United States", value: "UNITED STATES", isoCode: "US", states: usStates },
  {
    key: "United States Minor Outlying Islands",
    value: "UNITED STATES MINOR OUTLYING ISLANDS",
    isoCode: "UM"
  },
  { key: "Uruguay", value: "URUGUAY", isoCode: "UY" },
  { key: "Uzbekistan", value: "UZBEKISTAN", isoCode: "UZ" },
  { key: "Vanuatu", value: "VANUATU", isoCode: "VU" },
  { key: "Venezuela", value: "VENEZUELA", isoCode: "VE" },
  { key: "Viet Nam", value: "VIET NAM", isoCode: "VN" },
  {
    key: "Virgin Islands, British",
    value: "VIRGIN ISLANDS, BRITISH",
    isoCode: "VG"
  },
  { key: "Virgin Islands, U.S.", value: "VIRGIN ISLANDS, U.S.", isoCode: "VI" },
  { key: "Wallis and Futuna", value: "WALLIS AND FUTUNA", isoCode: "WF" },
  { key: "Western Sahara", value: "WESTERN SAHARA", isoCode: "EH" },
  { key: "Yemen", value: "YEMEN", isoCode: "YE" },
  { key: "Zambia", value: "ZAMBIA", isoCode: "ZM" },
  { key: "Zimbabwe", value: "ZIMBABWE", isoCode: "ZW" }
]

export default countries
