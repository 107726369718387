import React from "react";
import exact from "prop-types-exact";
import * as Types from "types";
import { Field } from "redux-form";
import { Input, Checkbox, Select } from "lp-components";
import { MomentDateInput } from "components";

const propTypes = {
  udf: Types.udf.isRequired,
};

const defaultProps = {};

function UdfField({ udf }) {
  const baseFieldProps = {
    name: udf.fieldName,
    label: udf.label,
    isRequired: udf.isRequired,
    validate: (val) => validateRequiredBookingUdf(val, udf),
  };

  const getOptions = () => {
    if (!udf.options) return [];
    return udf.options.map((option) => {
      return { key: option.trim(), value: option.trim() };
    });
  };

  switch (udf.inputType) {
    case Types.udfFieldTypes.BOOLEAN:
      return <Field {...baseFieldProps} component={Checkbox} />;
    case Types.udfFieldTypes.SELECT:
      return (
        <Field
          {...baseFieldProps}
          component={Select}
          placeholder="Select one"
          options={getOptions()}
        />
      );
    case Types.udfFieldTypes.DATE:
      return (
        <Field
          {...baseFieldProps}
          component={MomentDateInput}
          dateFormat="DD MMMM, YYYY"
          autoComplete="off"
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          nullByDefault
        />
      );
    case Types.udfFieldTypes.NUMERIC:
      return <Field {...baseFieldProps} component={Input} type="number" />;
    default:
      return <Field {...baseFieldProps} component={Input} />;
  }
}

const validateRequiredBookingUdf = (value, udf) => {
  if (!udf.isRequired) return;
  if (!value) return "Required field";
};

UdfField.propTypes = exact(propTypes);
UdfField.defaultProps = defaultProps;

export default React.memo(UdfField);
