import { handleActions } from 'redux-actions'
import { selectorForSlice } from 'lp-redux-utils'
import { handleSuccess } from 'lp-redux-api'
import { compose } from 'recompose'
import * as actions from './actions'
import * as apiActions from 'api-actions'
import * as LS from 'local-storage'
import { set } from 'lodash/fp'

const reducerKey = 'member'
const slice = 'root.member'

const isLoggedIn = () => LS.isLoggedIn()
const retrieveCurrentMember = () => LS.getMembershipDetails()

const initialState = {
  loggedIn: isLoggedIn(),
  currentMember: retrieveCurrentMember(),
}

const select = selectorForSlice(slice)

const setLoggedIn = (currentMember, obj) => {
  return compose(
    set('loggedIn', true),
    set('currentMember', currentMember)
  )(obj)
}

const setMemberDetails = (state, { payload: { data }}) => {
  LS.setMembershipDetails(data)
  return setLoggedIn(data, state)
}

const setMemberDetailsFromRegistration = (state, { payload: { data }}) => {
  const currentMember = {
    primaryMemberId: data.id,
    primaryMember: { ...data },
    secondaryMembers: [],
    token: data.token
  }
  
  LS.setMembershipDetails(currentMember)
  return setLoggedIn(currentMember, state)
}

const reducer = handleActions({
  [apiActions.requestMemberLogin]: handleSuccess(setMemberDetails),
  [actions.clearMemberSession]: (state) => {
    LS.clearMembershipOrderDetails()
    return compose(
      set('loggedIn', false),
      set('currentMember', {})
    )(state)
  },
  [apiActions.registerMember]: handleSuccess(setMemberDetailsFromRegistration),
}, initialState)

const selectors = {
  loggedIn: select('loggedIn'),
  currentMember: select('currentMember'),
  primaryMember: select('currentMember.primaryMember'),
  secondaryMembers: select('currentMember.secondaryMembers'),
}

export { reducer, selectors, reducerKey }
