import PropTypes from 'prop-types'

// Constants
export const ALL_ACTIVITIES_VENUE_SLUG = 'all'
export const TIMED_ACTIVITY_TYPE = 'TimedActivity'
export const CALENDAR_DATE_SELECT_TYPE = 'calendar_select'
export const DROPDOWN_DATE_SELECT_TYPE = 'dropdown_select'
export const DYNAMIC_PRICE_SELECT_TYPE = 'dynamic_price_select'
export const DEFAULT_ADDITIONAL_ACTIVITY_TIME_DAYS = 365
export const ACTIVITY_PATH_PREFIX = 'activities'
export const MEMBERSHIP_PATH_PREFIX = 'memberships'

export const udfFieldTypes = {
  STRING: 'string',
  BOOLEAN: 'boolean',
  NUMERIC: 'numeric',
  SELECT: 'select',
  DATE: 'date' 
}

export const ticketingOrder = PropTypes.shape({
  id: PropTypes.number,
  receipt: PropTypes.string.isRequired,
  coupon: PropTypes.string,
  bookingId: PropTypes.string,
  email: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
  donationAmountAmount: PropTypes.string,
  paymentMethod: PropTypes.string,
  totalPaid: PropTypes.number.isRequired,
  token: PropTypes.string,
  displayWaivers: PropTypes.bool,
  waivers: PropTypes.array,
  cart: PropTypes.object.isRequired
})

// Prop Types
export const activity = PropTypes.shape({
  id: PropTypes.number.isRequired,
  centamanId: PropTypes.number,
  type: PropTypes.string.isRequired,
  onDisplay: PropTypes.bool,
  displayName: PropTypes.string.isRequired,
  centamanDescription: PropTypes.string,
  dateSelectionType: PropTypes.string.isRequired,
  defaultStartDate: PropTypes.string,
  monthRange: PropTypes.number,
  image: PropTypes.string,
  thumbnail: PropTypes.string,
  learnMoreUrl: PropTypes.string,
  preview: PropTypes.string,
  serviceDetails: PropTypes.string,
  soldOutMessage: PropTypes.string,
  displaySoldOutMessage: PropTypes.bool,
  isFeatured: PropTypes.bool,
  active: PropTypes.bool,
  archived: PropTypes.bool,
  timed: PropTypes.bool,
  general: PropTypes.bool,
  collectAttendeeDetails: PropTypes.bool,
  collectAttendeeDob: PropTypes.bool,
  showSeason: PropTypes.bool,
  cutoffHours: PropTypes.number,
  position: PropTypes.number
})

export const activityTime = PropTypes.shape({
  id: PropTypes.integer,
  centamanId: PropTypes.number,
  bookingTypeId: PropTypes.number,
  activityId: PropTypes.number,
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  displayName: PropTypes.string,
  description: PropTypes.string,
  startDate: PropTypes.string,
  startTime: PropTypes.string,
  endDate: PropTypes.string,
  endTime: PropTypes.string,
  startDatetime: PropTypes.string,
  endDatetime: PropTypes.string,
  displayStartTime: PropTypes.string,
  displayEndTime: PropTypes.string,
  capacity: PropTypes.number,
  vacancy: PropTypes.number,
  soldOut: PropTypes.bool,
  validThrough: PropTypes.string,
  validDate: PropTypes.string,
  validDescription: PropTypes.string,
  isYearRound: PropTypes.bool
})

export const ticket = PropTypes.shape({
  id: PropTypes.number.isRequired,
  centamanId: PropTypes.number,
  activityId: PropTypes.number.isRequired,
  eventId: PropTypes.number,
  costRateId: PropTypes.number,
  price: PropTypes.number,
  type: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  description: PropTypes.string,
  categoryName: PropTypes.string,
  position: PropTypes.number,
  timed: PropTypes.bool,
  general: PropTypes.bool,
  addOn: PropTypes.bool
})

export const member = PropTypes.shape({
  id: PropTypes.number.isRequired,
  memberCode: PropTypes.number.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string,
  title: PropTypes.string,
  gender: PropTypes.string,
  dateOfBirth: PropTypes.string,
  isPrimary: PropTypes.bool.isRequired,
  memberships: PropTypes.array,
  token: PropTypes.string,
})

const membershipBase = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  package: PropTypes.bool.isRequired,
  centamanId: PropTypes.number.isRequired,
  displayName: PropTypes.string.isRequired,
  description: PropTypes.string,
  active: PropTypes.bool.isRequired,
  main: PropTypes.bool.isRequired,
  featured: PropTypes.bool.isRequired,
  addOn: PropTypes.bool.isRequired,
  location: PropTypes.string,
  membershipImage: PropTypes.string.isRequired,
  position: PropTypes.number,
  primaryOptions: PropTypes.array.isRequired,
  secondaryOptions: PropTypes.array.isRequired,
  addOns: PropTypes.array.isRequired,
}

export const membership = PropTypes.shape(membershipBase)

export const mappedMembership = PropTypes.shape({
  ...membershipBase,
  thumbnail: PropTypes.string.isRequired,
})

export const membershipCheckout = PropTypes.shape({
  totals: PropTypes.shape({
    total: PropTypes.number.isRequired,
    subtotal: PropTypes.number.isRequired,
    subtotalBeforeDiscounts: PropTypes.number.isRequired,
    totalTaxes: PropTypes.number.isRequired,
  }),
  items: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    priceAfterDiscounts: PropTypes.number.isRequired,
    subtotalBeforeDiscounts: PropTypes.number.isRequired,
    subtotal: PropTypes.number.isRequired,
    savings: PropTypes.number.isRequired,
    totalSavings: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }))
})

export const venue = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  address: PropTypes.string,
  streetAddress: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  phone: PropTypes.string,
  logo: PropTypes.string,
  locationMap: PropTypes.string,
  mobileLocationMap: PropTypes.string,
  emailLocationMap: PropTypes.string,
  locationMarker: PropTypes.string,
  googleMapLink: PropTypes.string,
  waiverLink: PropTypes.string,
  faqLink: PropTypes.string,
  position: PropTypes.number,
  activities: PropTypes.array
})

export const cart = PropTypes.shape({
  id: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
  costRateId: PropTypes.number,
  total: PropTypes.string,
  subtotal: PropTypes.string,
  taxes: PropTypes.string,
  discounts: PropTypes.string,
  donationAmount: PropTypes.string,
  lineItems: PropTypes.array
})

export const lineItem = PropTypes.shape({
  activityId: PropTypes.number,
  eventId: PropTypes.number,
  eventName: PropTypes.string,
  startDatetime: PropTypes.string,
  endDatetime: PropTypes.string,
  bookingUdfs: PropTypes.array
})

export const lineItemTicket = PropTypes.shape({
  id: PropTypes.number.isRequired,
  displayName: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  price: PropTypes.number.isRequired,
  total: PropTypes.string,
  subtotal: PropTypes.string,
  taxes: PropTypes.string,
  costRateId: PropTypes.number.isRequired,
  coupon: PropTypes.string,
  couponType: PropTypes.string,
  couponUnique: PropTypes.bool,
  couponDescription: PropTypes.string,
  createdAt: PropTypes.string,
  timed: PropTypes.bool.isRequired,
  general: PropTypes.bool.isRequired,
  attendee: PropTypes.object,
  attendeeUdfs: PropTypes.array,
})

export const udf = PropTypes.shape({
  active: PropTypes.bool,
  attendeeUdf: PropTypes.bool,
  fieldLength: PropTypes.number,
  fieldName: PropTypes.string,
  fieldType: PropTypes.string,
  id: PropTypes.number.isRequired,
  label: PropTypes.string,
  options: PropTypes.array,
  tabName: PropTypes.string,
  udfGroupName: PropTypes.string,
  udfType: PropTypes.string,
})