import React from 'react'

function NotFound () {
  return (
    <div>
      <main className="content page-error">
        <h1>Membership Not Found</h1>
        <p>
          Sorry about that, but looks like the membership you're looking for either
          doesn't exist or was moved.
        </p>
        <a className="button-chill" href="https://utaholympiclegacy.org/">
          Back to Home
        </a>
      </main>
    </div>
  );
}

export default NotFound
