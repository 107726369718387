import React from 'react'
import { compose } from 'redux'
import { modifyProps } from 'lp-utils'
import PropTypes from 'prop-types'

const propTypes = {
  item: PropTypes.object.isRequired,
  classList: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
}

function NavItem ({ item: { title, url, object, objectSlug }, classList, target, rel }) {
  return (
    <li className={ classList } >
      <a  className={ `${ object } ${ objectSlug }` } 
          href={ url } 
          target={ target }
          rel={ rel }>
        { title }
      </a>
    </li>
  )
}

NavItem.propTypes = propTypes

function modify({ targetVal }) {
  const linkTargetVal = targetVal ? targetVal : '_blank'
  const relVal = linkTargetVal === '_blank' ? 'noopener noreferrer' : ''

  return {
    target: linkTargetVal,
    rel: relVal,
  }
}

export default compose(
  modifyProps(modify),
)(NavItem)