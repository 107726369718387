import { addDays, addMonths, endOfMonth, startOfMonth } from 'date-fns'

// Given a date, returns the first and last visible dates
// on the calendar widget page that includes the date.
function calculateVisibleCalendarRange(date, months=2) {
  const startDate = startOfMonth(date)
  const endDate = calculateCalendarRangeEndDate(date, months)
  return [startDate, endDate]
}

// ----- PRIVATE -----

// Returns the date corresponding to the last possible date that may be visible
// on a DateInput calendar widget (including overlap from the next month)
function calculateCalendarRangeEndDate(startDate, months) {
  const monthEnd = endOfMonth(addMonths(startDate, months))

  // The max that could be shown is 6 days (otherwise row will be hidden)
  // note: getDay returns the _day of the week_, not the date number
  return addDays(monthEnd, 6 - monthEnd.getDay())
}

export default calculateVisibleCalendarRange
