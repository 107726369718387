import React  from "react";
import PropTypes from "prop-types"
import * as Types from "types";
import { compose } from "recompose";
import { lpForm } from "lp-form";
import { noop } from 'lodash'
import { SubmitButton } from 'lp-components'
import { UdfField } from 'components'

const propTypes = {
  activity: Types.activity.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func
};

const defaultProps = {
  onCancel: noop
};

function BookingUdfForm({
  activity,
  handleSubmit,
  onCancel
}) {

  return (
    <form onSubmit={handleSubmit}>
        <div className="udf-details" aria-label="Enter booking details">
            <h4>Booking Details</h4>
            {activity.bookingUdfs.map(udf => {
                return <UdfField key={udf.id} udf={udf}/>
            })}
            <div className="button-group-large">
              <SubmitButton>Save Details</SubmitButton>
              <button className="link-primary" type="button" onClick={() => onCancel()} aria-label="Back to select date and time">Cancel</button>
            </div>
        </div>
    </form>
  );
}


BookingUdfForm.propTypes = propTypes
BookingUdfForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: "booking-udf-form",
    constraints: {},
    submitOnChange: false,
  })
)(BookingUdfForm);
