/* 
  This module follows GA4 structure for GTM.
  More events and parameters can be found here: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm
*/

function addDataForEvent(event, eventDetails) {
  window.dataLayer.push({ ecommerce: null }); // Recommended by Google, to clear the previous ecommerce object.
  window.dataLayer.push({
    event,
    ecommerce: eventDetails
  });
}

export function trackViewCart(cart) {
  addDataForEvent('view_cart', {
    currency: 'USD',
    value: cart.total,
    items: mapTicketsToItemsFromCart(cart)
  })
}

export function trackViewItemList() {
  addDataForEvent('view_item_list', {
    items: []
  })
}

export function trackViewItem(itemId) {
  addDataForEvent('view_item', {
    items: [
      {
        item_id: itemId,
        item_category: 'Activities',
        affiliation: 'API Checkout'
      }
    ]
  })
}

export function trackAddToCart(activityId, price) {
  addDataForEvent('add_to_cart', {
    currency: 'USD',
    value: price,
    items: [
      {
        item_id: activityId,
        affiliation: 'API Checkout'
      }
    ]
  })
}

export function trackBeginCheckout(cart) {
  addDataForEvent('begin_checkout', {
    currency: 'USD',
    value: Number(cart.total),
    items: mapTicketsToItemsFromCart(cart)
  })
}

export function trackAddPaymentInfo(cart) {
  addDataForEvent('add_payment_info', {
    event_category: "Add Payment Info",
    event_label: cart,
  })
}

export function trackPurchase(order) {
  addDataForEvent('purchase', {
    event_category: "Purchase",
    event_label: order,
  })
}

export function trackPurchaseFromOrder(order, cart) {
  addDataForEvent('purchase', {
    transaction_id: order.receipt,
    currency: 'USD',
    value: Number(order.totalPaid),
    shipping: 0,
    coupon: mapCoupons(cart.appliedCoupons),
    tax: cart.taxes,
    items: mapTicketsToItemsFromCart(cart)
  })
}

export function trackPurchaseFrom(transactionId, total, coupon, ticketType, tickets) {
  addDataForEvent('purchase', {
    transaction_id: transactionId,
    currency: 'USD',
    value: total,
    shipping: 0,
    coupon: coupon,
    tax: 0,
    items: tickets.map((ticket) => ({
      item_id: ticket.id,
      item_name: ticket.description,
      item_category: ticketType,
      affiliation: 'Checkout API',
      price: Number(ticket.totalPer),
      quantity: ticket.quantity
    }))
  })
}

function mapTicketsToItemsFromCart(cart) {
  return cart.lineItems.flatMap(lineItem => {
    return lineItem.lineItemTickets.map(ticket => {
      return {
        item_id: ticket.id,
        item_name: ticket.displayName,
        item_category: lineItem.displayName,
        affiliation: 'Checkout API',
        price: Number(ticket.price),
        quantity: ticket.quantity,
        coupon: ticket.coupon,
      }
    })
  })
}

function mapCoupons(coupons) {
  return coupons.join(',')
}
