import React from 'react'
import { Route, } from 'react-router'
import * as Views from './views'
import Layout from './Layout'
import { AuthorizedRoute } from 'lp-components'
import { isAuthenticated } from 'auth'
import { NotFound } from './components'

const Routes = (
  <Route path="memberships" component={ Layout }>
    <AuthorizedRoute authFunction={ isAuthenticated } redirect="/member/login">
      <Route path="not-found" component={ NotFound } />
      <Route path=":id" component={ Views.MembershipsDetail } />
      <Route path=":id/add-ons" component={ Views.AddOns } />
      <Route path=":id/billing" component={ Views.Billing } />
      <Route path="confirmation/:orderId" component={ Views.OrderConfirmation } />
    </AuthorizedRoute>
    <Route path="*" component={ NotFound } />
  </Route>
)

export default Routes
