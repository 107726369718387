import { isString } from 'lodash'

// Remove characters after last occurrence of a delimiter string
// E.g. removeCharactersAfter('hello', 'l') -> 'hell'
function removeCharactersAfter (str, delimiter) {
  if (!str.includes(delimiter)) return str
  return str.split(delimiter).slice(0, -1).join(delimiter)
}

// Remove timezone from time string (anything after +/-)
function removeTimezoneFromTimeString (timeString) {
  if (timeString.includes('Z')) return timeString
  if (timeString.includes('+')) return removeCharactersAfter(timeString, '+')
  if (timeString.includes('-')) return removeCharactersAfter(timeString, '-')
  return timeString
}

// Remove timezone from ISO string 
function stripTimezoneFromDateString (dateString) {
  if (!dateString.includes('T')) return dateString
  const [ date, time ] = dateString.split('T')
  return date + 'T' + removeTimezoneFromTimeString(time)
}

// Remove timezone from date string or date object
function stripTimezone (date) {
  if (!date) return '' // this is required for Redux-Form (https://redux-form.com/7.4.2/docs/api/field.md/#-code-format-value-name-gt-formattedvalue-code-optional-)
  return isString(date)
    ? stripTimezoneFromDateString(date)
    : new Date(stripTimezoneFromDateString(date.toISOString()))
}

export default stripTimezone
