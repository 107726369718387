import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'lp-components'

const propTypes = {
  handleHide: PropTypes.func.isRequired,
}

function TermsModal({ handleHide }) {
  return (
    <Modal
      onClose={() => handleHide()}
    >
      <div className="terms-modal">
      <button type="button" onClick={handleHide} className="modal-close" aria-label="View terms and conditions"/>
      <h4>Utah Olympic Legacy Foundation Terms of Service</h4>
      <div className="modal-content">
        <p>
          The Utah Athletic Foundation, dba Utah Olympic Legacy Foundation (the
          “<strong>Foundation</strong>,” “<strong>we</strong>,” or “
          <strong>our</strong>”) operates{' '}
          <a
            className="external"
            href="https://utaholympiclegacy.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://utaholympiclegacy.org/
          </a>{' '}
          and related subdomains (collectively referred to as the “
          <strong>Site</strong>”). By using, subscribing to, or otherwise
          engaging the Site, the tickets, products, or services sold on or by
          the Site, our applications (including mobile applications), or used in
          connection with any of the foregoing (collectively, the “
          <strong>Services</strong>”), you agree that these terms and conditions
          (these “<strong>Terms</strong>”) shall govern any use that arises out
          of or relates to this Site or the Services.
        </p>
        <ol>
          <li>
            <h5>
              <u>Scope of These Terms</u>.
            </h5>
            <p>
              These Terms apply to your use of the Site and related subdomains.
              By using our Site, signing up for email, using our products or
              Services, participating in surveys, or otherwise providing
              information to us, you are expressing your consent to and
              acceptance of these Terms, including the policies and practices
              described herein. If you disagree with any part of these Terms,
              then you may not access the Services. The materials contained on
              this Site are protected by applicable copyright and trademark law.{' '}
            </p>
          </li>
          <li>
            <h5>
              <u>Special Note for Parents</u>.
            </h5>
            <p>
              The Site is a general audience site and is not designed or
              intended to target children younger than 13. We do not knowingly
              collect personal information from any person younger than 13.
            </p>
          </li>
          <li>
            <h5>
              <u>Electronic Communications</u>.
            </h5>
            <p>
              When you visit our Site or send emails to us, you are
              communicating with us electronically, and thereby consenting to
              receive communications from us electronically or by other means
              available. We will communicate with you electronically, by
              telephone or by facsimile. It is further understood that all
              agreements, notices, disclosures and other communications that we
              provide to you electronically satisfy any legal requirement that
              such communication be in writing.
            </p>
          </li>
          <li>
            <h5>
              <u>License and Access</u>.
            </h5>
            <p>
              The entire contents of the Site, including but not limited to:
              text, designs, graphics, photographs, illustrations, formats,
              logos, icons, scripts, page headers, Flash movies, images, audio
              and music clips, digital downloads, data compilations and
              software, and all other written and related materials (the{' '}
              “<strong>IP</strong>"), are owned, controlled, or licensed by the
              Foundation, and are protected by domestic and international
              copyright and trademark laws. You may not modify, copy, reproduce,
              republish, upload, post, transmit or distribute any portion of the
              Site, its contents, or the IP without the prior express written
              consent of the Foundation. If you believe any content appearing on
              our Site constitutes a copyright infringement of another party’s
              rights, please contact us immediately at{' '}
              <a
                className="external"
                href="mailto:info@uolf.org?Subject=Terms Question"
                target="_blank"
                rel="noopener noreferrer"
              >
                info@uolf.org
              </a>{' '}
              to notify us of this potential infringement.
            </p>
            <p>
              The Foundation grants you limited, non-exclusive,
              non-transferable, non-sublicensable access to the Site, Services,
              and related IP. Except to the extent of the access expressly
              provided herein, these Terms shall not be deemed, construed or
              interpreted as a grant, transfer or conveyance to you or any third
              party, expressly by inference or by estoppel, of any right, title
              or interest in, or license or right to use, all or any portions of
              the IP. Accordingly, all rights, title, and interest related to or
              arising from the Site, Services, and/or the IP not expressly
              granted to you in these Terms are reserved and retained by the
              Foundation.
            </p>
            <p>
              It is further understood and agreed that the Foundation cannot and
              does not guarantee or warrant that files made available for
              downloading through the Site or Services will be free of infection
              or viruses, worms, Trojan horses or other code that manifests
              contaminating or destructive properties. It is your responsibility
              to implement sufficient safeguards and procedures to ensure that
              any files obtained through the Site or Services are free from such
              contamination.
            </p>
          </li>
          <li>
            <h5>
              <u>Submitted Content</u>.
            </h5>
            <p>
              We may allow you to submit comments, feedback, suggestions,
              questions, reviews and other related content through the Site
              and/or the Services (the “<strong>Content</strong>”). By
              submitting the Content, you represent and warrant that: (i) you
              are the sole author and owner of the Content; (ii) you are at
              least 18 years of age, or if you are under the age of 18, you are
              at least 13 years of age and your submission has been under the
              supervision of a parent or legal guardian who agrees to be bound
              by these Terms; and (iii) the Content you supply will not violate
              these Terms, or any applicable law and will not cause any injury
              to any person or entity.
            </p>
            <p>
              You also represent and warrant that the Content is NOT, as
              determined by the Foundation in its sole discretion, any of the
              following: false, inaccurate, misleading, a violation of any
              local, state, federal, international or other applicable law, or
              otherwise obscene, derogatory, defamatory, threatening, harassing,
              abusive, slanderous, hateful embarrassing to any other person or
              entity, including as such relates to the harassment, degradation,
              intimidation, or victimization of an individual or group of
              individuals on the basis of religion, gender, sexual orientation,
              race, ethnicity, age or disability; an infringement on the rights
              of others, such as infringements on any patent, copyright,
              trademarks, trade secret, publicity or privacy rights; an
              advertisement, solicitation or spam link to other websites or
              individuals, except if such an advertisement or solicitation has
              been expressly consented to in writing by the Foundation; a chain
              letter or pyramid scheme, or part of a chain letter or pyramid
              scheme; an impersonation of, and does not purport to impersonate,
              another business, person, or entity, including the Foundation and
              its employees and agents; or a virus or other harmful computer
              code, and does not contain a virus or other harmful code.
            </p>
            <p>
              When you submit any Content to the Site and/or to the Services, or
              otherwise make <em>any submission</em> to the Site and/or to the
              Services, you automatically grant and/or warrant the Foundation a
              royalty-free, perpetual, irrevocable, worldwide, unlimited, and
              non-exclusive license to use, reproduce, create derivative works
              from, modify, publish, edit, translate distribute, perform and
              display the submission and/or the Content in any media or medium,
              or any form, format or forum whether now known or hereafter
              developed. You also grant the Foundation the right to utilize your
              name in connection with all advertising, marketing and promotional
              materials related thereto.
            </p>
            <p>
              You understand and acknowledge that we have the right, but not the
              obligation, to monitor all Content and any submission made to or
              on the Site or the Services. We have the right, in our sole
              discretion and for any reason, to edit, delete, move, or to refuse
              to post any Content or any other submission to the Site or the
              Services. Notwithstanding the foregoing, you are solely
              responsible for any Content and/or any other submission you make
              to or on the Site or the Services, and you agree to indemnify the
              Foundation for all claims related to or arising from such Content
              and/or submissions.
            </p>
          </li>
          <li>
            <h5>
              <u>Your Privacy</u>.
            </h5>
            <p>
              You acknowledge that any personal information or data you provide
              to the Site or the Services will be used by us in accordance with
              our Privacy Policy found{' '}
              <a
                className="external"
                href="https://utaholympiclegacy.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              , which is incorporated in these Terms.
            </p>
          </li>
          <li>
            <h5>
              <u>Pricing and Descriptions</u>.
            </h5>
            <p>
              We do NOT represent, nor warrant, that descriptions, pricing
              information, promotions, offers, transit times and availability,
              or any other content found on, or relating to, the Site or any of
              the Services is accurate, complete, reliable, current, or
              error-free.
            </p>
            <p>
              You understand and acknowledge that we have the right, but not the
              obligation, to correct any errors, inaccuracies or omissions, and
              to otherwise change or update any information or content on the
              Site or the Services in our sole discretion without any prior
              notice to you.
            </p>
            <p>
              YOU EXPRESSLY ACKNOWLEDGE THAT WE MAY, WITHOUT PRIOR NOTICE TO
              YOU, CANCEL ANY ORDER MADE ON THE SITE OR TO PURCHASE ANY OF THE
              SERVICES, IF SUCH ORDER IS MADE IN CONNECTION WITH ANY
              DESCRIPTION, INFORMATION, OR CONTENT ON THE SITE OR SERVICES THAT
              IS NOT ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR-FREE.
            </p>
          </li>
          <li>
            <h5>
              <u>No Refund and Resale Prohibited</u>.
            </h5>
            <p>
              No refunds or replacements will be provided for any of the
              Services or for any purchase made on the Site or Services for ANY
              reason (including, but not limited to, inclement weather).
            </p>
            <p>
              You further acknowledge and agree that any tickets to any events
              purchased on or through the Site or Services (
              “<strong>Tickets</strong>”) have no cash value, are nonrefundable,
              and are nontransferable. For the avoidance of doubt, reselling any
              Ticket is strictly prohibited and will result in the cancellation
              of such Ticket.
            </p>
            <p>
              Each “Any Day,” “Annual,” or “Season” Ticket is only valid through
              its respective expiration date. All other tickets are only valid
              for the date and time specified for such Ticket.
            </p>
          </li>
          <li>
            <h5>
              <u>Changes to Operating Hours</u>.
            </h5>
            <p>
              We reserve the right in our sole discretion to change operating
              hours and/or operating days for all facilities (including the Utah
              Olympic Park, Utah Olympic Oval, and Soldier Hollow Nordic Center,
              among others), activities, and events for{' '}
              <em>
                any reason relating to weather, safety and security, and
                operational considerations.
              </em>
            </p>
          </li>
          <li>
            <h5>
              <u>Force Majeure</u>.
            </h5>
            <p>
              We will not be liable for any delay or failure to perform any
              obligation under these Terms where the delay or failure results
              from any cause beyond our reasonable control, including acts of
              God, labor disputes or other industrial disturbances, electrical
              or power outages, utilities or other telecommunication failures,
              earthquake, storms, or other elements of nature, blockages,
              embargoes, riots, acts or orders of government, acts of terrorism,
              or war.
            </p>
          </li>
          <li>
            <h5>
              <u>Our Right to Terminate</u>.
            </h5>
            <p>
              The Foundation, its sole discretion and for any reason, reserves
              the right terminate your access and use of the Site or any of the
              Services without notice.{' '}
            </p>
          </li>
          <li>
            <h5>
              <u>Changes to These Terms</u>.
            </h5>
            <p>
              The Foundation, in its sole discretion and for any reason, may
              change and amend these Terms, the Privacy Notice, or any terms and
              conditions, features, characteristics, and aspects relating to any
              of the Services. If changes occur, we will also show the date of
              revision. By using the Site to which the Privacy Policy applies
              you agree that we may notify you of changes in our privacy policy
              through this Site. Your continued use of the Site and/or Services
              constitutes acceptance and an agreement to be bound by the then
              current version of these Terms.
            </p>
          </li>
          <li>
            <h5>
              <u>Limitation of Liability</u>.
            </h5>
            <p>
              THE SITE AND SERVICES, AND ALL INFORMATION, CONTENT, MATERIALS,
              PRODUCTS, SOFTWARE, AND ALL OTHER SERVICES INCLUDED ON OR MADE
              AVAILABLE TO YOU THROUGH THE SITE OR ANY OF THE SERVICES (
              “<strong>FOUNDATION CONTENT AND PRODUCTS</strong>”) ARE PROVIDED
              ON AN “AS IS” AND “AS AVAILABLE” BASIS, UNLESS OTHERWISE EXPRESSLY
              INDICATED IN WRITING. THE FOUNDATION MAKES NO REPRESENTATIONS OR
              WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF
              THE SITE, ANY OF THE SERVICES, OR ANY OF THE FOUNDATION CONTENT
              AND PRODUCTS.
            </p>
            <p>
              TO THE FULL EXTENT PERMISSIBLE BY LAW, THE FOUNDATION DISCLAIMS
              ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
              IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
              PURPOSE.
            </p>
            <p>
              TO THE FULLEST EXTENT PERMISSIBLE BY LAW, THE FOUNDATION WILL NOT
              BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE ACCESS OR
              USE OF THE SITE, SERVICES, OR THE FOUNDATION CONTENT AND PRODUCTS,
              INCLUDING, BUT NOT LIMITED TO, DIRECT, INDIRECT, INCIDENTAL,
              PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN
              WRITING.
            </p>
          </li>
          <li>
            <h5>
              <u>Indemnification</u>.
            </h5>
            <p>
              You agree to indemnify, defend and hold harmless the Foundation,
              its officers, directors, partners, employees, agents, licensors,
              vendors and any third-party information provider to the Site from
              and against all losses, expenses, damages and costs, including
              attorney’s fees, arising from, or relating to, any violation of
              these Terms (including negligent or wrongful conduct) by you or
              your use and access of the Site, Services, and Foundation Content
              and Products.
            </p>
          </li>
          <li>
            <h5>
              <u>Governing Law</u>.
            </h5>
            <p>
              These Terms are governed by and construed in accordance with the
              laws of the State of Utah and you agree submit all disputes
              relating thereto to the exclusive jurisdiction of the state and
              federal courts in and for the State of Utah.
            </p>
          </li>
          <li>
            <h5>
              <u>Limits of These Terms</u>.
            </h5>
            <p>
              Our Site may link to external sites that are not operated by us.
              Please be aware that we have no control over the content and
              policies of those sites, and do not accept responsibility or
              liability for their respective content, terms and conditions, or
              privacy practices. The inclusion of any link does not imply
              endorsement by the Foundation of the site. Use of any such linked
              site is at the user's own risk.
            </p>
          </li>
          <li>
            <h5>
              <u>Severability</u>.
            </h5>
            <p>
              Every provision of these Terms is intended to be severable. If any
              aspect of these Terms, or any change to any aspect of these Terms,
              is illegal or invalid for any reason whatsoever, such illegality
              or invalidity shall not affect the validity of the remainder of
              these Terms or any changes to these Terms that are otherwise legal
              and valid.
            </p>
          </li>
        </ol>
        <p>Last updated June 11, 2019.</p>
      </div>
    </div>
    </Modal>
  )
}

TermsModal.displayName = 'TermsModal'
TermsModal.propTypes = propTypes

export default TermsModal
