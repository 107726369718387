import { configureApi } from 'lp-requests'
import { middleware as configureMiddleware } from 'lp-redux-api'

export const { API_URL } = process.env

export const config = {
  root: API_URL,
  mode: 'cors',
}

export const middleware = configureMiddleware(config)
export const api = configureApi(config)
