import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Field } from 'redux-form'
import { compose } from "recompose"
import { Input, SubmitButton } from 'lp-components'
import { lpForm } from "lp-form"

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
}

const defaultProps = {}

function CouponForm ({ handleSubmit }) {
    return (
        <form onSubmit={handleSubmit}>
            <Field
                component={Input}
                name="couponCode"
                label="Promo Code (Optional)"
                className="promo-input"
            />
            <SubmitButton className="button-promo">
                Add
            </SubmitButton>
        </form>
    )
}

CouponForm.propTypes = exact(propTypes)
CouponForm.defaultProps = defaultProps

export default compose(
    lpForm({
      name: "coupon-form",
      constraints: {
          'couponCode': { presence: true }
      }
    })
)(CouponForm)