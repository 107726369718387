import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from './reducer'
import { onMount, modifyProps } from 'lp-utils'
import * as apiActions from 'api-actions'
import PropTypes from 'prop-types'
import * as Types from 'types'
import Nav from './components/nav'
import ResponsiveNav from './components/responsive-nav'

const propTypes = {
  navItems: PropTypes.array,
  cart: Types.cart,
  currentFlow: PropTypes.string.isRequired
}

const defaultProps = {
  cart: null
}

function Header ({ navItems, cart, currentFlow }) {
  return (
    <header className="navigation">
      <Nav items={ navItems } cart={cart} currentFlow={currentFlow}/>
      <ResponsiveNav items={ navItems }/>
    </header>
  )
}

function mapStateToProps(state) {
  return {
    navItems: selectors.navItems(state),
  }
}

const mapDispatchToProps = {
  fetchNavItems: apiActions.fetchNavItems,
}

function modify({ navItems }) {
  return {
    navItems: navItems.filter((item) => item.title !== 'Home'),
  }
}

Header.propTypes = propTypes
Header.defaultProps = defaultProps

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  onMount('fetchNavItems'),
  modifyProps(modify),
)(Header)
