import React from 'react'
import PropTypes from 'prop-types'
import { SelectOption } from 'components'

const propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  optionValues: PropTypes.array.isRequired,
  selectClass: PropTypes.string,
}

function Select ({ onChange, label, optionValues, selectClass }) {
  return (
    <fieldset className={ selectClass }>
      { label && <label>{ label }</label> }
      <select onChange={ (e) => onChange(e.target.value) }>
        { optionValues.map((o, i) => <SelectOption key={ i } optionTitle={ o.name } optionValue={ o.value } />) }
      </select>
    </fieldset>
  )
}

Select.propTypes = propTypes

export default Select
