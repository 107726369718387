import React from 'react'
import PropTypes from 'prop-types'
import { modal } from 'utils'

const propTypes = {
  handleHide: PropTypes.func.isRequired,
  limitQuantity: PropTypes.number,
}

function SecondaryMembershipLimitModal ({ handleHide, limitQuantity }) {
  return (
    <div>
      <div onClick={ handleHide } className="modal-close"></div>
      <h4>Family Members</h4>
      <div className="modal-content">
        <p>You are only allowed to add { limitQuantity } FREE family members on this page. Continue through to the next screen to add more Family members at an additional cost.</p>
        <a
          onClick={ handleHide }
          className="button-primary">
          Okay
        </a>
      </div>
    </div>
  )
}

SecondaryMembershipLimitModal.displayName = 'SecondaryMembershipLimitModal'

SecondaryMembershipLimitModal.propTypes = propTypes

export default modal({
  destroyOnHide: true,
  innerClassName: 'show-limit',
})(SecondaryMembershipLimitModal)
