import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import Content from './content'

const propTypes = {
    message: PropTypes.string
}

const defaultProps = {
    message: 'An error occurred.'
}

function ErrorState ({ message }) {
    return (
        <Content>
            <p className="error">{message}</p>
        </Content>
    )
}

ErrorState.propTypes = exact(propTypes)
ErrorState.defaultProps = defaultProps

export default React.memo(ErrorState)