import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connectModal, show, hide, destroy } from 'redux-modal'
import classnames from 'classnames'

// A HOC for modals. Uses the displayName of the wrapped component as a key for the reducer.
// You can show and hide this modal using the redux-modal functions show(displayName) and hide(displayName)

function modal (opts = {}) {
  return function modal (WrappedComponent) {
    const displayName = opts.name || WrappedComponent.displayName || WrappedComponent.name
    if (!displayName) throw new Error('Must provide name option or wrap a named component')
    const innerClassNames = 'card modal-inner ' + (opts.innerClassName || '')
    function ModalWrapper ({ show, handleHide, ...rest }) {
      return (
        <div className={classnames('modal', { 'modal-warning': opts.warning })}>
          <div className={classnames('modal-fade-screen', { 'is-active': show })}>
            <div className={ innerClassNames }>
              <WrappedComponent handleHide={handleHide} {...rest} />
            </div>
          </div>
        </div>
      )
    }
    ModalWrapper.propTypes = {
      show: PropTypes.bool,
      handleHide: PropTypes.func,
    }
    const connectedModalWrapper = compose(
      connectModal({
        name: displayName,
        destroyOnHide: false,
        ...opts,
      })
    )(ModalWrapper)
    // Rename the whole thing to the modal name so we can call actions on it
    connectedModalWrapper.displayName = displayName
    connectedModalWrapper.show = (...args) => show(displayName, ...args)
    connectedModalWrapper.hide = (...args) => hide(displayName, ...args)
    connectedModalWrapper.destroy = (...args) => destroy(displayName, ...args)
    return connectedModalWrapper
  }
}

export default modal
