import { handleActions } from 'redux-actions'
import { setOnSuccess } from 'lp-redux-api'
import { selectorForSlice } from 'lp-redux-utils'
import * as apiActions from 'api-actions'

const reducerKey = 'memberships'
const slice = 'root.memberships'

const initialState = {}

const reducer = handleActions({
  [apiActions.fetchMembershipDetails]: setOnSuccess('membership'),
  [apiActions.updateMembershipCheckout]: setOnSuccess('checkout'),
  [apiActions.fetchMembershipOrder]: setOnSuccess('order'),
}, initialState)

const select = selectorForSlice(slice)

const selectors = {
  selectedMembership: select('membership'),
  checkout: select('checkout'),
  order: select('order'),
}

export { reducer, selectors, reducerKey }
