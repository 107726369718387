import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { lpForm } from "lp-form";
import BookingCalendar from "../components/calendar-components/BookingCalendar";
import TimeSlotSelector from "../components/calendar-components/TimeSlotSelector";

const propTypes = {
  activityLineItem: PropTypes.object,
  change: PropTypes.func.isRequired,
  orderedActivityTimes: PropTypes.object,
  setSelectedActivityTime: PropTypes.func.isRequired,
};

const defaultProps = {};

function CalendarBookingForm({
  activityLineItem,
  change,
  orderedActivityTimes,
  setSelectedActivityTime,
}) {
  return (
    <div aria-label="Step 1">
      <h3 className="h4">
        <span className="step">1</span>
        Select Date and Time
      </h3>
      <div className="calendar-wrapper">
        <BookingCalendar
          activityLineItem={activityLineItem}
          change={change}
          orderedActivityTimes={orderedActivityTimes}
        />
        <TimeSlotSelector
          activityLineItem={activityLineItem}
          change={change}
          orderedActivityTimes={orderedActivityTimes}
          setSelectedActivityTime={setSelectedActivityTime}
        />
      </div>
    </div>
  );
}

CalendarBookingForm.propTypes = propTypes;
CalendarBookingForm.defaultProps = defaultProps;

const beforeSubmit = (formValues) => {
  const { selectedTime: selectedActivity } = formValues;

  return selectedActivity ? selectedActivity : undefined;
};

export default compose(
  lpForm({
    beforeSubmit,
    name: "calendar-booking-form",
    constraints: {},
    submitOnChange: true,
  })
)(CalendarBookingForm);
